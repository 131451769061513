/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import LocalShipping from '@material-ui/icons/LocalShipping';
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import DateRange from '@material-ui/icons/DateRange';
import CallMade from '@material-ui/icons/CallMade';
import CallReceived from '@material-ui/icons/CallReceived';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import GraphicEq from '@material-ui/icons/GraphicEq';
import HourglassEmpty from '@material-ui/icons/HourglassEmpty';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Button from 'components/CustomButtons/Button.jsx';
import Refresh from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import purple from '@material-ui/core/colors/purple';
import firebase from '../../config/config';

const checkFundQueueTotal = firebase
  .functions()
  .httpsCallable('dashboard-checkFundQueueTotal');

const db = firebase.firestore();

class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transporters: '',
      truckers: '',
      fundQueue: 0,
      payablePending: '',
      commission: '',
      receivablePending: '',
      gfvThisMonth: 0,
      gfvLastMonth: 0,
      netCommissionThisMonth: 0,
      netCommissionLastMonth: 0,
      currentMonthName: '',
      lastMonthName: '',
      loader: false,
    };
  }

  componentDidMount() {
    this.getCounts();
    this.getGlobalMonthsData();
  }

  getGlobalMonthsData() {
    const date = new Date();
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const currentYear = date.getFullYear();
    const currentMonthName = monthNames[date.getMonth()];
    let lastMonthName = monthNames[date.getMonth() - 1];
    const currentDocPath = `${currentMonthName}${currentYear}`;
    let lastDocPath = `${lastMonthName}${currentYear}`;
    if (currentMonthName === 'January') {
      const lastYear = currentYear - 1;
      lastMonthName = 'December';
      lastDocPath = `${lastMonthName}${lastYear}`;
    }
    this.setState({
      currentMonthName,
      lastMonthName,
    });
    const currentMonthRef = db.collection('MonthDashboard').doc(currentDocPath);
    const lastMonthRef = db.collection('MonthDashboard').doc(lastDocPath);
    currentMonthRef.onSnapshot(doc => {
      this.setState({
        gfvThisMonth: doc.data().freight,
        netCommissionThisMonth: doc.data().commission,
      });
    });
    lastMonthRef.onSnapshot(doc => {
      this.setState({
        gfvLastMonth: doc.data().freight,
        netCommissionLastMonth: doc.data().commission,
      });
    });
  }

  getCounts() {
    const countsRef = db.collection('Counters');
    countsRef.doc('Transporters').onSnapshot(doc => {
      this.setState({ transporters: doc.data().count });
    });
    countsRef.doc('Truckers').onSnapshot(doc => {
      this.setState({ truckers: doc.data().count });
    });
    countsRef.doc('Balances').onSnapshot(doc => {
      this.setState({
        payablePending: doc.data().payablePending,
        receivablePending: doc.data().receivablePending,
        commission: doc.data().commission,
        fundQueue: doc.data().fundQueue,
      });
    });
  }

  /**
   * Used to calculate the percent between the two numbers
   * @param originalNumber number
   * @param newNumber number
   */
  percentCalculate(thisMonth, lastMonth) {
    if (thisMonth !== 0) {
      const decrease = (thisMonth - lastMonth) / thisMonth;
      const calculatedPercent = decrease * 100;
      return calculatedPercent.toFixed(2);
    }
    return 0;
  }

  refreshFinanceQueue() {
    this.setState({
      loader: true,
    });
    checkFundQueueTotal()
      .then(res => {
        // console.log('Fund Queue Added', res);
        this.setState({
          loader: false,
        });
      })
      .catch(err => {
        this.setState({
          loader: false,
        });
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="yellow" stats icon>
                <CardIcon color="warning">
                  <GraphicEq />
                </CardIcon>
                <p className={classes.cardCategory}>GFV</p>
                <h3 className={classes.cardTitle}>
                  <i className="fa fa-inr" aria-hidden="true" />
                  {Intl.NumberFormat('en-IN').format(
                    this.state.gfvThisMonth
                  )}{' '}
                  <small />
                </h3>
                {/* <span className={classes.stats} style={{ float: 'left' }}>
                  <p style={{ marginTop: '3px', fontSize: '24px' }}>Preview</p>
                </span> */}
                <span className={classes.stats}>
                  <DateRange
                    style={{ height: '17px', padding: '0', margin: '0' }}
                  />
                  <p style={{ marginTop: '3px' }}>
                    {this.state.currentMonthName}
                  </p>
                </span>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <i className="fa fa-inr" aria-hidden="true" />{' '}
                  {Intl.NumberFormat('en-IN').format(this.state.gfvLastMonth)}
                  &nbsp;&nbsp;{' '}
                  <DateRange
                    style={{ height: '13px', padding: '0', margin: '0' }}
                  />{' '}
                  {this.state.lastMonthName}
                </div>
                <div className={classes.stats}>
                  <p style={{ float: 'right' }}>
                    {this.percentCalculate(
                      this.state.gfvThisMonth,
                      this.state.gfvLastMonth
                    ) > 0 ? (
                      <ArrowUpward style={{ color: 'green' }} />
                    ) : (
                      <ArrowDownward style={{ color: 'red' }} />
                    )}
                    {this.percentCalculate(
                      this.state.gfvThisMonth,
                      this.state.gfvLastMonth
                    )}
                    %
                  </p>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <AttachMoney />
                </CardIcon>
                <p className={classes.cardCategory}>Net Commission</p>
                <h3 className={classes.cardTitle}>
                  <i className="fa fa-inr" aria-hidden="true" />
                  {Intl.NumberFormat('en-IN').format(
                    this.state.netCommissionThisMonth
                  )}{' '}
                  <small />
                </h3>
                {/* <span className={classes.stats} style={{ float: 'left' }}>
                  <p style={{ marginTop: '3px', fontSize: '24px' }}>Preview</p>
                </span> */}
                <span className={classes.stats}>
                  <DateRange
                    style={{ height: '20px', padding: 0, margin: 0 }}
                  />
                  <p style={{ marginTop: '3px' }}>
                    {this.state.currentMonthName}
                  </p>
                </span>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <i className="fa fa-inr" aria-hidden="true" />{' '}
                  {Intl.NumberFormat('en-IN').format(
                    this.state.netCommissionLastMonth
                  )}
                  &nbsp;&nbsp;
                  <DateRange
                    style={{ height: '13px', padding: '0', margin: '0' }}
                  />{' '}
                  {this.state.lastMonthName}
                </div>
                <div className={classes.stats}>
                  <p style={{ float: 'right' }}>
                    {this.percentCalculate(
                      this.state.netCommissionThisMonth,
                      this.state.netCommissionLastMonth
                    ) > 0 ? (
                      <ArrowUpward style={{ color: 'green' }} />
                    ) : (
                      <ArrowDownward style={{ color: 'red' }} />
                    )}
                    {this.percentCalculate(
                      this.state.netCommissionThisMonth,
                      this.state.netCommissionLastMonth
                    )}
                    %
                  </p>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary">
                  <HourglassEmpty />
                </CardIcon>
                <p className={classes.cardCategory}>Fund Queue</p>
                <h3 className={classes.cardTitle}>
                  <i className="fa fa-inr" aria-hidden="true" />
                  {Intl.NumberFormat('en-IN').format(this.state.fundQueue)}{' '}
                  <small />
                </h3>
                <span className={classes.stats}>
                  <p style={{ height: '2rem' }} />
                </span>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>Total sales</div>
                <div className={classes.stats}>
                  {this.state.loader ? (
                    <CircularProgress
                      className={classes.progress}
                      style={{ color: purple[500] }}
                      thickness={2}
                    />
                  ) : (
                    <Button
                      size="md"
                      simple
                      color="primary"
                      onClick={() => this.refreshFinanceQueue()}
                      style={{ padding: '0px' }}
                    >
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  )}
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <CallMade />
                </CardIcon>
                <p className={classes.cardCategory}>Payable Pending</p>
                <h3 className={classes.cardTitle}>
                  <i className="fa fa-inr" aria-hidden="true" />
                  {Intl.NumberFormat('en-IN').format(
                    this.state.payablePending
                  )}{' '}
                  <small />
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Pending Payable Amount
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <CallReceived />
                </CardIcon>
                <p className={classes.cardCategory}>Receivable Pending</p>
                <h3 className={classes.cardTitle}>
                  <i className="fa fa-inr" aria-hidden="true" />
                  {Intl.NumberFormat('en-IN').format(
                    this.state.receivablePending
                  )}{' '}
                  <small />
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Pending Receivable Amount
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <i className="fa fa-inr" aria-hidden="true" />
                </CardIcon>
                <p className={classes.cardCategory}>Commission</p>
                <h3 className={classes.cardTitle}>
                  <i className="fa fa-inr" aria-hidden="true" />
                  {Intl.NumberFormat('en-IN').format(
                    this.state.commission
                  )}{' '}
                  <small />
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Commission
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="rose" stats icon>
                <CardIcon color="warning">
                  <TransferWithinAStation />
                </CardIcon>
                <p className={classes.cardCategory}>Transporters</p>
                <h3 className={classes.cardTitle}>
                  {this.state.transporters} <small />
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Last 24 Hours
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <LocalShipping />
                </CardIcon>
                <p className={classes.cardCategory}>Truckers</p>
                <h3 className={classes.cardTitle}> {this.state.truckers}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Last 24 Hours
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

AdminDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(AdminDashboard);
