/* eslint-disable no-prototype-builtins */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/Icons
import NoteAdd from '@material-ui/icons/NoteAdd';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import 'react-datepicker/dist/react-datepicker.css';
import Table from 'components/Table/Table.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import AddAlert from '@material-ui/icons/AddAlert';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import firebase from '../../../config/config';

import {
  switchActivePage,
  setCurrentTransaction,
  setPayableAthTransactions,
} from '../../../reducers/transactions';
import apiCaller from '../../../utils/apiCallerOutstanding';

const db = firebase.firestore();
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');
const submitAthPayable = firebase
  .functions()
  .httpsCallable('payable-submitAthPayable');

const moment = require('moment');

const { REACT_APP_OFFICE_REPORTS_API } = process.env;

class AthHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      payableTransactions: [
        {
          color: 'info',
          data: ['28-09-2019', '10,000', 'message is'],
        },
      ],
      amount: '',
      amountState: '',
      message: '',
      messageState: '',
      date: moment().format('MMM Do YY'),
      isAthForm: false,
      tc: false,
      isOtpNotification: false,
      isLoading: false,
      startDate: moment(),
      alert: '',
      transactionTypes: ['IMPS', 'NEFT'],
      currentTransactionType: '',
      classicModal: false,
      otp: '',
      responseData: {},
      isLoadingOtp: false,
      isLocked: false,
      payableId: '',
      otpError: '',
      isLoadingCancel: false,
      errorAlert: false,
      amountErrorMessage: '',
      errMessage: '',
      isTruckerBlacklisted: false,
      showPanMessage: false,
    };
    this.hideDetailedForm = this.hideDetailedForm.bind(this);
    this.showAthForm = this.showAthForm.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onCancelAlert = this.onCancelAlert.bind(this);
    this.handleType = this.handleType.bind(this);
  }

  componentDidMount() {
    const { currentTransactionData, activeRole } = this.props;
    const { transactionId } = currentTransactionData;
    const transRef = db.collection('Transactions').doc(transactionId);
    transRef.onSnapshot(transactionDoc => {
      const transaction = transactionDoc.data();
      if (transaction.isLocked && activeRole === 'finance') {
        const responseData = {};
        const payableRef = db
          .collection('Transactions')
          .doc(transactionId)
          .collection('Payable')
          .doc(transaction.payableId);
        payableRef.get().then(payableDoc => {
          if (payableDoc.exists) {
            responseData.amount = payableDoc.data().amount;
            responseData.message = payableDoc.data().message;
            responseData.payableId = payableDoc.data().payableId;
            responseData.transactionId = transactionId;
            responseData.truckerId = transaction.truckerId;
            this.setState({
              responseData,
              isLocked: true,
            });
          }
        });
      } else {
        this.setState({
          isLocked: false,
          classicModal: false,
        });
      }
    });
    const athRef = db
      .collection('Transactions')
      .doc(currentTransactionData.transactionId)
      .collection('Payable')
      .where('type', '==', 'ATH')
      .where('status', '==', 'success');
    athRef.orderBy('createdAt', 'asc').onSnapshot(athRes => {
      const athTransactions = [];
      athRes.forEach(element => {
        if (
          !currentTransactionData.isEditedTransaction &&
          currentTransactionData.status === 'approved' &&
          !element.data().void
        ) {
          athTransactions.push({
            color: 'info',
            data: [
              // element.data().date,
              moment(element.data().createdAt.toDate()).format('DD/MM/YY'),
              Intl.NumberFormat('en-IN').format(element.data().amount),
              element.data().message,
            ],
          });
        } else if (element.data().void) {
          athTransactions.push({
            color: 'info',
            data: [
              // element.data().date,
              moment(element.data().createdAt.toDate()).format('DD/MM/YY'),
              Intl.NumberFormat('en-IN').format(element.data().amount),
              element.data().message,
              ' ',
            ],
            void: element.data().void,
          });
        }
      });
      // eslint-disable-next-line react/destructuring-assignment
      this.props.setPayableAthTransactions(athTransactions);
    });
    this.handleGetTrucker(currentTransactionData);
  }

  async handleGetTrucker(currentTransactionData) {
    const { truckerId } = currentTransactionData;

    const userDetails = firebase.auth().currentUser;

    const { Aa } = userDetails;
    const token = Aa;
    try {
      const endPoint = 'search-office-user';
      const body = {
        type: 'truckers_blacklist_status',
        trucker_id: truckerId,
        pan: '',
        contact_person: '',
        phone_number: '',
        name: '',
        limit: 10,
        offset: 0,
      };
      const response = await apiCaller(endPoint, body, `Bearer ${token}`);
      const data = response?.data;
      const isBlacklistedTrucker = data?.is_blacklist;
      if (isBlacklistedTrucker) {
        this.setState({ isTruckerBlacklisted: true });
      } else {
        this.setState({ isTruckerBlacklisted: false });
      }
    } catch (e) {
      console.log(e);
    }
  }

  // eslint-disable-next-line react/sort-comp
  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  onCancelAlert() {
    this.setState({
      alert: null,
    });
  }

  hideDetailedForm() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.switchActivePage(false);
  }

  /**
   * will return the notification snackbar
   * @param {string} place
   */
  showNotification(place) {
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 5000);
    }
  }

  handleChange(date) {
    this.setState({
      startDate: date,
      date: moment(date).format('MMM Do YY'),
    });
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  verifyOtp(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value) && value.toString().length > 5) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyNarration(value) {
    // const emailRex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    const numberRex = new RegExp('^[A-Za-z0-9_ ]+$');
    if (
      numberRex.test(value) &&
      value.toString().length > 3 &&
      value.toString().length < 30
    ) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'otp':
        if (this.verifyOtp(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'narration':
        if (this.verifyNarration(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        this.setState({
          [`${stateName}State`]: 'success',
          [stateName]: event.target.value,
        });
        break;
    }
  }

  /**
   * Will Activate Form for Adding New Payable Transaction
   * @function
   */
  showAthForm() {
    const { isAthForm } = this.state;
    this.setState({
      isAthForm: !isAthForm,
    });
  }

  getForm() {
    const { classes } = this.props;
    const {
      amount,
      amountState,
      message,
      messageState,
      isLoading,
      amountErrorMessage,
    } = this.state;

    return (
      <Card style={{ marginTop: '1px', paddingBottom: '1px' }}>
        <CardBody style={{ marginBottom: '1px', paddingBottom: '1px' }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Amount"
                id="trucker"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: amount,
                  onChange: event => this.change(event, 'amount', 'number'),
                }}
                success={amountState === 'success'}
                error={amountState === 'error'}
              />
              <p style={{ color: 'red' }}>{amountErrorMessage}</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Narration"
                id="trucker"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: message,
                  onChange: event => this.change(event, 'message', 'narration'),
                  type: 'length',
                }}
                success={messageState === 'success'}
                error={messageState === 'error'}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter style={{ paddingTop: '0px' }}>
          {isLoading ? (
            <CircularProgress
              className={classes.progress}
              style={{ color: purple[500] }}
              thickness={7}
            />
          ) : (
            <React.Fragment>
              <Button
                className={classes.updateProfileButton}
                onClick={() => this.showAthForm()}
              >
                Cancel
              </Button>
              <Button
                color="info"
                className={classes.updateProfileButton}
                onClick={() => this.submitAth()}
              >
                Submit
              </Button>
            </React.Fragment>
          )}
        </CardFooter>
      </Card>
    );
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable() {
    const { classes, athTransactions } = this.props;
    return (
      <Card
        style={{ marginTop: '2px', paddingBottom: '1px', marginBottom: '5px' }}
      >
        {athTransactions.length > 0 ? (
          <CardBody className={classes.customCardContentClass}>
            <Table
              hover
              tableHead={['Date', 'Amount', 'Narration']}
              tableData={athTransactions}
            />
          </CardBody>
        ) : (
          <p
            className={classes.textCenter}
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            No Transactions Available
          </p>
        )}
      </Card>
    );
  }

  getPanWarning() {
    const { classes } = this.props;
    return (
      <Card
        style={{ marginTop: '2px', paddingBottom: '1px', marginBottom: '5px' }}
      >
        <p
          className={classes.textCenter}
          style={{ color: '#D32F2F', marginTop: '10px', marginBottom: '10px' }}
        >
          PAN is invalid. Please update a vaild PAN.
        </p>
      </Card>
    );
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    const { currentPayableAmount } = this.props;
    const { amount, message } = this.state;
    const { currentTransactionData } = this.props;
    let { limitPayable } = currentTransactionData;
    if (limitPayable === undefined) {
      limitPayable = false;
    }
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify  Amount
      if (this.verifyNumber(amount)) {
        this.setState({
          amountState: 'success',
        });
      } else {
        this.setState({ amountState: 'error' });
        error = true;
      }

      if (limitPayable) {
        if (Number(amount) > 0 && Number(amount) <= 99000) {
          this.setState({
            amountState: 'success',
            amountErrorMessage: '',
          });
        } else {
          this.setState({
            amountState: 'error',
            amountErrorMessage: 'Please Enter Valid Amount',
          });
          error = true;
        }
      } else if (Number(amount) > 0) {
        this.setState({
          amountState: 'success',
          amountErrorMessage: '',
        });
      } else {
        this.setState({
          amountState: 'error',
          amountErrorMessage: 'Please Enter Valid Amount',
        });
        error = true;
      }

      // Verify whether the amount is less the currentReceivableAmount
      if (parseInt(amount, 10) > parseInt(currentPayableAmount, 10)) {
        this.setState({
          amountState: 'error',
        });
        error = true;
      }

      // Verify  message
      if (this.verifyNarration(message)) {
        this.setState({
          messageState: 'success',
        });
      } else {
        this.setState({ messageState: 'error' });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('validation Failed');
      }
    });
  }

  handleType = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  /**
   * Will Submit the Payable history to Database
   * @function
   */
  submitAth = () => {
    const {
      userDetails,
      systemDetails,
      currentTransactionData,
      classes,
    } = this.props;
    const { amount, message } = this.state;
    this.setState({ isLoading: true });
    this.validateAllFields()
      .then(
        success => {
          if (success) {
            const body = {
              amount,
              message,
              type: 'ATH',
              truckerId: currentTransactionData?.truckerId,
              transactionId: currentTransactionData.transactionId,
              transactionSerial: currentTransactionData.transactionSerial,
              // date, Handled in Cloud Function, used Server Timestamp
              createdBy: userDetails.uid,
              createdByName: userDetails.name,
            };

            const auditBody = {
              collection: 'Payable',
              updatedBy: userDetails.uid,
              systemDetails,
              type: 'Finance Entry',
              message: `${userDetails.email} added Payable Entry (Payout) for Rs.${amount} ATH for Transaction ${currentTransactionData.transactionSerial}`,
            };
            auditLog(auditBody);
            submitAthPayable(body)
              .then(receivableResult => {
                const response = receivableResult.data;
                if (response.message && response.statusCode === 200) {
                  this.setState({
                    isLodingOtp: false,
                    classicModal: false,
                    errMessage: '',
                  });
                  this.clearForm();
                  this.showNotification('tc');
                }
                this.setState({
                  isLoading: false,
                });
                this.setState({
                  alert: (
                    <SweetAlert
                      success
                      style={{ display: 'block', marginTop: '-100px' }}
                      title="Please Wait........"
                      showConfirm={false}
                      confirmBtnCssClass={`${classes.button} ${classes.success}`}
                    />
                  ),
                });

                setTimeout(() => {
                  window.location.reload();
                }, 3000);
              })
              .catch(err => {
                if (err.message === 'pan-invalid') {
                  this.setState({
                    showPanMessage: true,
                  });
                }

                let errMessage = 'ATH Payout Failed, Please try Again!';
                if (err.hasOwnProperty('message')) {
                  if (err.message === 'No Fund Account Details Found') {
                    errMessage = 'Fund Account not found for this Account.';
                  }
                }
                this.setState({
                  errMessage,
                });
                this.clearForm();
                this.showNotification('errorAlert');
                const errorBody = {
                  message: `${userDetails.email} tried to add Payable Entry Rs.${amount} ATH for Transaction ${currentTransactionData.transactionSerial} but FAILED`,
                  status: 'fail',
                  error: err,
                  updatedBy: userDetails.uid,
                  errorMessage: err.message,
                  collection: 'Payable',
                  systemDetails,
                };
                auditLog(errorBody);
              });
          } else {
            console.log('err');
            this.setState({
              isLoading: false,
            });
          }
        },
        err => {
          this.setState({
            isLoading: false,
          });
        }
      )
      .catch(error => {
        this.setState({
          isLoading: false,
        });
      });
  };

  /**
   * will clear the form
   * @function
   */
  clearForm() {
    this.showAthForm();
    this.setState({
      amount: '',
      message: '',
      amountState: '',
      messageState: '',
      isLoading: false,
      isLoadingOtp: false,
      responseData: {},
      currentTransactionType: '',
      otp: '',
      otpState: '',
      otpError: '',
      isLoadingCancel: false,
      amountErrorMessage: '',
    });
  }

  render() {
    const { classes, activeRole, currentTransactionData } = this.props;
    const {
      alert,
      isAthForm,
      tc,
      errorAlert,
      isTruckerBlacklisted,
    } = this.state;

    return (
      <GridContainer>
        {alert}
        <GridItem xs={12} sm={12} md={12}>
          {this.getTable()}
          {this.state.showPanMessage && this.getPanWarning()}
          <GridContainer>
            {isAthForm ? (
              <GridItem xs={12} sm={12} md={12}>
                {this.getForm()}
              </GridItem>
            ) : (
              <GridItem xs={12} sm={12} md={12}>
                {(activeRole === 'finance' ||
                  (activeRole === 'finance-payable' &&
                    !isTruckerBlacklisted)) &&
                !currentTransactionData.void ? (
                  <Button
                    color="info"
                    className={classes.updateProfileButton}
                    onClick={() => this.showAthForm()}
                  >
                    <NoteAdd />
                    Add More
                  </Button>
                ) : null}
              </GridItem>
            )}
          </GridContainer>
        </GridItem>
        <Snackbar
          place="tc"
          color="info"
          icon={AddAlert}
          message="ATH History Added Successfully"
          open={tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
        <Snackbar
          place="tc"
          color="danger"
          icon={AddAlert}
          message={this.state.errMessage}
          open={errorAlert}
          closeNotification={() => this.setState({ errorAlert: false })}
          close
        />
        <GridItem xs={12} sm={12} md={12} />
      </GridContainer>
    );
  }
}

AthHistory.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  // currentTransactionData: state.transactions.currentTransactionData,
  transactions: state.transactions.transactions,
  payableTransactions: state.transactions.payableTransactions,
  athTransactions: state.transactions.payableAthTransactions,
  currentPayableAmount: state.transactions.currentPayableAmount,
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setCurrentTransaction,
      setPayableAthTransactions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(AthHistory));
