/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Rating } from 'react-simple-star-rating';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import firebase from '../../../config/config';

const { REACT_APP_OFFICE_REPORTS_API } = process.env;
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    textAlign: 'center',
    marginTop: '30px',
  },
  title: { textTransform: 'capitalize', fontSize: 24 },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 0px',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
  stars: { marginRight: '10px' },
  submitButton: {
    backgroundColor: '#3366FF',
    color: 'white',
    fontSize: '12px',
    textTransform: 'capitalize',
    padding: '5px 16px',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'lightgray',
    borderRadius: '30px',
    padding: '10px',
    // margin: '0px 30px',
  },
  contentBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default function RatingsCard({ activeRole, truckerData }) {
  const classes = useStyles();
  const [rating, setRating] = useState(0); // initial rating value
  const [score, setScore] = useState(0);
  const [token, setToken] = useState('');
  const [fetching, setFetching] = useState(false);
  // Catch Rating value
  const handleRating = rate => {
    setRating(rate);
    // other logic
  };
  const getOverAllRating = async value => {
    setFetching(true);
    const body = {
      source: 'trucker',
      trucker_id: truckerData.truckerId,
    };
    const endPoint = 'total-ratings';
    try {
      const response = await axios.post(
        `${REACT_APP_OFFICE_REPORTS_API}${endPoint}`,
        body,
        {
          headers: { Authorization: `Bearer ${value || token}` },
        }
      );
      const overallRating = response.data[0].overall_truckers_rating;
      setScore(parseInt(overallRating));
      setRating(parseInt(overallRating * 20));
      setFetching(false);
    } catch (e) {
      console.log(e);
      setFetching(false);
    }
  };
  useEffect(() => {
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    setToken(Aa);
    getOverAllRating(Aa);
  }, []);
  return (
    <Card className={classes.root}>
      <CardContent className={classes.contentBox}>
        <div className={classes.titleBox}>
          {fetching ? (
            <div
              style={{
                backgroundColor: 'lightgray',
                color: 'lightgray',
                borderRadius: '10px',
              }}
            >
              <Typography variant="h6" className={classes.title}>
                Overall Rating
              </Typography>
            </div>
          ) : (
            <Typography variant="h6" className={classes.title}>
              Overall Rating
            </Typography>
          )}
        </div>

        <div className={classes.flex}>
          <Rating
            emptyColor="white"
            onClick={handleRating}
            ratingValue={rating}
            size={45}
            classNames={classes.stars}
            readonly
          />
          <Typography
            variant="caption"
            display="block"
            style={{ marginLeft: '10px' }}
          >
            {score} out of 5
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}
