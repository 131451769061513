import AdminDashboard from 'views/AdminDashboard/AdminDashboard.jsx';
import AdminApproveTransporter from 'views/AdminApproveTransporter/AdminApproveTransporter.jsx';
import AdminApproveTrucker from 'views/AdminApproveTrucker/AdminApproveTrucker.jsx';
import AdminApproveTransaction from 'views/AdminApproveTransaction/AdminApproveTransaction.jsx';
import Transactions from 'views/Transactions/OpsTransactions.jsx';
import OutstandingReportsView from 'views/Outstanding Reports/OutstandingReportsView.jsx';
import Truckers from 'views/Truckers/AdminViewTruckers.jsx';
import Transporters from 'views/Transporters/AdminViewTransporters.jsx';
import AuditLog from 'views/AuditLog/AuditLog.jsx';
import Branches from 'views/Branches/BranchesManagement.jsx';
import OutstandingList from 'views/Outstandings/OutstandingList.jsx';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import LocalShipping from '@material-ui/icons/LocalShipping';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import RecentActors from '@material-ui/icons/RecentActors';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import UserManagement from 'views/Users/UserManagement.jsx';
import GroupAdd from '@material-ui/icons/GroupAdd';
import LocationCity from '@material-ui/icons/LocationCity';
// import AddTransporter from "../views/Users/AddTransporter";
import PayoutsTable from 'views/Transactions/Sections/PayoutsTable.jsx';
import PremiumTransporters from '../views/PremiumTransporters/PremiumTransporters';
import AddPremiumTransporter from '../views/PremiumTransporters/AddPremiumTransporter';
import Cities from '../views/Users/Cities';
import TrafficTracking from '../views/Traffic-Tracking/TrafficTracking';
import PODAgeingView from '../views/PODAgeing/PODAgeingView';
import TruckerReports from '../views/Outstanding Reports/TruckerReports/TruckerReports';
import TransporterOutstandingReports from '../views/Outstanding Reports/TransporterOutstanding/TransporterOutstandingReports';

const dashRoutes = {
  opsDashRoutes: [
    {
      path: '/ops/dashboard',
      name: 'Dashboard',
      icon: DashboardIcon,
      component: AdminDashboard,
    },
    {
      collapse: true,
      path: '/ops/approve',
      name: 'Approve',
      icon: AssignmentTurnedIn,
      state: 'openApprove',
      views: [
        {
          path: '/ops/approve/transporter',
          name: 'Transporter',
          mini: 'T',
          component: AdminApproveTransporter,
        },
        {
          path: '/ops/approve/trucker',
          name: 'Trucker',
          mini: 'T',
          component: AdminApproveTrucker,
        },
        {
          path: '/ops/approve/transaction',
          name: 'Transaction',
          mini: 'T',
          component: AdminApproveTransaction,
        },
      ],
    },
    {
      path: '/ops/transactions',
      name: 'View Transactions',
      icon: SwapHoriz,
      component: Transactions,
    },
    {
      path: '/ops/truckers',
      name: 'View Truckers',
      icon: LocalShipping,
      component: Truckers,
    },
    {
      path: '/ops/transporters',
      name: 'View Transporters',
      icon: TransferWithinAStation,
      component: Transporters,
    },
    {
      collapse: true,
      path: '/ops',
      name: 'Reports',
      icon: AccountBalanceWalletIcon,
      // state: 'openApprove',
      views: [
        {
          path: '/ops/podAgeing',
          name: 'POD Ageing',
          mini: 'P',
          component: PODAgeingView,
        },
      ],
    },

    {
      collapse: true,
      path: '/ops/manage',
      name: 'Manage',
      icon: PeopleIcon,
      state: 'openUsers',
      views: [
        {
          path: '/ops/manage/users',
          name: 'Users',
          mini: 'U',
          component: UserManagement,
        },
        {
          path: '/ops/manage/branches',
          name: 'Branches',
          mini: 'A',
          component: Branches,
        },
        {
          path: '/ops/manage/traffic-tracking',
          name: 'Traffic Tracking',
          mini: 'T',
          component: TrafficTracking,
        },
      ],
    },
    {
      collapse: true,
      path: '/ops/premiumTransporters',
      name: 'Premium Trasnporters',
      icon: GroupAdd,
      state: 'openPremiumTransporters',
      views: [
        {
          path: '/ops/premiumTransporters/view',
          name: 'View',
          mini: 'V',
          component: PremiumTransporters,
        },
        {
          path: '/ops/premiumTransporters/add',
          name: 'Add',
          mini: 'A',
          component: AddPremiumTransporter,
        },
      ],
    },
    {
      path: '/ops/payouts',
      name: 'Payouts',
      icon: DashboardIcon,
      component: PayoutsTable,
    },
    {
      redirect: true,
      path: '/ops',
      pathTo: '/ops/dashboard',
      name: 'Dashboard',
    },
  ],
  adminDashRoutes: [
    {
      path: '/ops/dashboard',
      name: 'Dashboard',
      icon: DashboardIcon,
      component: AdminDashboard,
    },
    {
      collapse: true,
      path: '/ops/approve',
      name: 'Approve',
      icon: AssignmentTurnedIn,
      state: 'openApprove',
      views: [
        {
          path: '/ops/approve/transporter',
          name: 'Transporter',
          mini: 'T',
          component: AdminApproveTransporter,
        },
        {
          path: '/ops/approve/trucker',
          name: 'Trucker',
          mini: 'T',
          component: AdminApproveTrucker,
        },
        {
          path: '/ops/approve/transaction',
          name: 'Transaction',
          mini: 'T',
          component: AdminApproveTransaction,
        },
      ],
    },
    {
      path: '/ops/transactions',
      name: 'View Transactions',
      icon: SwapHoriz,
      component: Transactions,
    },

    {
      path: '/ops/truckers',
      name: 'View Truckers',
      icon: LocalShipping,
      component: Truckers,
    },
    {
      path: '/ops/transporters',
      name: 'View Transporters',
      icon: TransferWithinAStation,
      component: Transporters,
    },
    {
      collapse: true,
      path: '/ops/outstanding',
      name: 'Reports',
      icon: AccountBalanceWalletIcon,
      // state: 'openApprove',
      views: [
        {
          path: '/ops/outstanding/transporter',
          name: 'Customer Statement',
          mini: 'C',
          component: OutstandingList,
        },

        {
          path: '/ops/podAgeing',
          name: 'POD Ageing',
          mini: 'P',
          component: PODAgeingView,
        },
        {
          path: '/ops/transporterOutstanding',
          name: 'Transporter Outstanding',
          mini: 'T O',
          component: TransporterOutstandingReports,
        },
      ],
    },
    {
      collapse: true,
      path: '/ops/manage',
      name: 'Manage',
      icon: PeopleIcon,
      state: 'openUsers',
      views: [
        {
          path: '/ops/manage/users',
          name: 'Users',
          mini: 'U',
          component: UserManagement,
        },
        {
          path: '/ops/manage/branches',
          name: 'Branches',
          mini: 'B',
          component: Branches,
        },
        {
          path: '/ops/manage/traffic-tracking',
          name: 'Traffic Tracking',
          mini: 'T',
          component: TrafficTracking,
        },
      ],
    },
    {
      collapse: true,
      path: '/ops/premiumTransporters',
      name: 'Premium Trasnporters',
      icon: GroupAdd,
      state: 'openPremiumTransporters',
      views: [
        {
          path: '/ops/premiumTransporters/view',
          name: 'View',
          mini: 'V',
          component: PremiumTransporters,
        },
        {
          path: '/ops/premiumTransporters/add',
          name: 'Add',
          mini: 'A',
          component: AddPremiumTransporter,
        },
      ],
    },
    {
      path: '/ops/auditlog',
      name: 'Audit Log',
      icon: RecentActors,
      component: AuditLog,
    },
    {
      path: '/ops/payouts',
      name: 'Payouts',
      icon: DashboardIcon,
      component: PayoutsTable,
    },
    {
      redirect: true,
      path: '/ops',
      pathTo: '/ops/dashboard',
      name: 'Dashboard',
    },
  ],
};
export default dashRoutes;
