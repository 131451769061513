/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-var */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from 'components/Card/Card.jsx';
import AddIcon from '@material-ui/icons/Add';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import ApartmentIcon from '@material-ui/icons/Apartment';
import Chip from '@material-ui/core/Chip';
import { CircularProgress, Typography } from '@material-ui/core';
import firebase from 'firebase/app';
import UsersDataGrid from './UsersDataGrid.jsx';
import Myfirebase from '../../config/config';
import AlertMessageBar from './AlertMessageBar';

const db = Myfirebase.firestore();
var users = [];
const AddBranches = () => {
  const [branchName, setBranchName] = useState('');
  const [existingBranches, setExistingBranches] = useState();
  const [error, setError] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [addingBranch, setAddingBranch] = useState(false);
  const [branchAdded, setBranchAdded] = useState(false);
  const [fetchedUsers, setFetchedUsers] = useState(false);
  const handleBranch = event => {
    setBranchName(event.target.value);
  };
  const getExistingBranches = async () => {
    await db
      .collection('Branches')
      // .where('branches', 'not-in', branchName)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          const data = doc.data();

          // users.push(data);
          setExistingBranches(data.branches);
        });
        setFetchedUsers(true);
      })
      .catch(error => {
        console.log('Error getting documents: ', error);
        // setFetching(false);
      });
  };
  const getExistingUsers = async () => {
    setFetchedUsers(false);
    users = [];
    // setFetching(true);
    await db
      .collection('Users')
      // .where('branches', 'not-in', branchName)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          const data = doc.data();

          users.push(data);
        });
        setFetchedUsers(true);
      })
      .catch(error => {
        setFetchedUsers(true);
        // setFetching(false);
      });
  };

  const addNewBranch = async () => {
    await db
      .collection('Branches')
      .doc('Branches')
      .update({
        branches: firebase.firestore.FieldValue.arrayUnion(branchName),
      });
    setAddingBranch(false);
    setTrigger(true);
    getExistingUsers();
  };
  const handleBranchAddition = async event => {
    await db
      .collection('Branches')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          const data = doc.data().branches;
          if (data.includes(branchName)) {
            setError(true);
          } else {
            // users = [];
            setAddingBranch(true);
            setBranchAdded(true);
            addNewBranch();
          }
        });
      })
      .catch(error => {
        console.log('Error getting documents: ', error);
      });
    // event.preventDefault();
  };

  useEffect(() => {
    users = [];
    getExistingBranches();
  }, []);
  return (
    <div>
      <Card>
        <CardHeader>
          <CardIcon color="primary">
            <ApartmentIcon style={{ color: 'white' }} />
          </CardIcon>
          <h4>
            Add Branches <small />
          </h4>
        </CardHeader>
        <CardBody>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} direction="row">
                  <Grid container spacing={2}>
                    <Grid item>
                      <TextField
                        id="outlined-basic"
                        label="Add Branch"
                        variant="outlined"
                        size="small"
                        error={error}
                        onChange={handleBranch}
                        helperText={error ? 'Branch already present' : ''}
                      />
                    </Grid>
                    <Grid item>
                      {addingBranch ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          variant="contained"
                          style={{ background: '#3DAC16', color: 'white' }}
                          startIcon={<AddIcon />}
                          disabled={branchName === ''}
                          onClick={handleBranchAddition}
                        >
                          Add
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Current Branches
                      </Typography>
                    </Grid>
                    {existingBranches?.map(branch => (
                      <Grid item>
                        <Chip variant="outlined" size="small" label={branch} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {branchAdded ? (
              <Grid item xs={12}>
                {fetchedUsers ? (
                  <UsersDataGrid
                    users={users}
                    branchName={branchName}
                    refreshUsers={() => getExistingUsers()}
                  />
                ) : (
                  ''
                )}
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </CardBody>
      </Card>
      <AlertMessageBar
        trigger={trigger}
        message="you have added a new Branch and may want to add user's to the same"
        closeBar={val => setTrigger(val)}
      />
    </div>
  );
};

export default AddBranches;
