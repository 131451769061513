/* eslint-disable radix */
/* eslint-disable no-useless-return */
/* eslint-disable no-nested-ternary */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import swal from 'sweetalert';
import firebase from '../../../../config/config';

const buttonStyles = {
  background: '#3366FF',
  color: 'white',
  fontSize: '12px',
  textTransform: 'capitalize',
};

const editButtonStyles = {
  background: '#AFB2B7',
  color: 'black',
  fontSize: '12px',
  textTransform: 'capitalize',
};
const BalancePaymentTerms = ({ activeRole, selectedTransporterData }) => {
  const db = firebase.firestore();
  const userDetails = firebase.auth().currentUser;
  const auditLog = firebase.functions().httpsCallable('utilities-auditLog');
  const [numberOfDays, setNumberofDays] = React.useState(
    selectedTransporterData?.balanceTermsDays
  );
  const [isToBeEdited, setIstoBeEdited] = React.useState(
    selectedTransporterData?.balanceTermsPercentage
  );
  const [percentage, setPercentage] = React.useState(
    selectedTransporterData?.balanceTermsPercentage
  );
  const [updating, setUpdating] = React.useState(false);
  const handleTextChangeForDays = e => {
    const regex = /^[0-9]*$/;
    if (regex.test(e.target.value)) {
      setNumberofDays(e.target.value);
    } else return;
  };
  const handleTextChangeForPercent = e => {
    const regex = /^\d*\.?\d*$/;
    if (regex.test(e.target.value) && e.target.value < 101) {
      setPercentage(e.target.value);
    } else return;
  };
  const handleSubmitNumberOfLocations = () => {
    setUpdating(true);
    const transporterRef = db
      .collection('Transporters')
      .doc(selectedTransporterData.transporterId);

    return transporterRef
      .update({
        balanceTermsDays: parseInt(numberOfDays),
        balanceTermsPercentage: percentage,
        modifiedAt: new Date(),
      })
      .then(() => {
        setUpdating(false);
        swal(
          'Success!',
          `You have set ${numberOfDays} as Number Of Days & ${percentage}% percentage as Balance Payment Terms for  ${selectedTransporterData?.name} `,
          'success'
        );
        setIstoBeEdited(true);
        const auditBody = {
          collection: 'Transporters',
          numberOfLocationsSetBy: userDetails?.uid,
          type: 'Updated Advance Payments of Terms in Transporters',
          message: `${userDetails?.email} in Transporters collection inside ${selectedTransporterData?.transporterId} set  ${numberOfDays} as Number Of Days & ${percentage}% percentage as Advance Payment Terms  `,
          uploadedAt: new Date(),
        };
        auditLog(auditBody);
      })
      .catch(error => {
        // The document probably doesn't exist.
        setUpdating(false);
        swal('Error!', `Something went wrong`, 'error');
      });
  };
  return (
    <div>
      <Typography
        variant="subtitle2"
        gutterBottom
        style={{ textTransform: 'capitalize', marginBottom: '10px' }}
      >
        Balance Payment Terms
      </Typography>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <Grid
          item
          xs={activeRole === 'ops' || activeRole === 'finance' ? 5 : 6}
        >
          {activeRole === 'sales' ||
          activeRole === 'branch-ops' ||
          activeRole === 'traffic' ? (
            <TextField
              size="small"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              value={numberOfDays}
              disabled
            />
          ) : (
            <TextField
              size="small"
              id="outlined-basic"
              variant="outlined"
              value={numberOfDays}
              fullWidth
              placeholder="days"
              disabled={isToBeEdited}
              onChange={handleTextChangeForDays}
            />
          )}
        </Grid>
        <Grid
          item
          xs={activeRole === 'ops' || activeRole === 'finance' ? 5 : 6}
        >
          {activeRole === 'sales' ||
          activeRole === 'branch-ops' ||
          activeRole === 'traffic' ? (
            <TextField
              size="small"
              id="outlined-basic"
              variant="outlined"
              fullWidth
              value={percentage}
              disabled
            />
          ) : (
            <TextField
              size="small"
              id="outlined-basic"
              variant="outlined"
              value={percentage}
              fullWidth
              placeholder="%"
              disabled={isToBeEdited}
              onChange={handleTextChangeForPercent}
            />
          )}
        </Grid>
        {activeRole === 'ops' || activeRole === 'finance' ? (
          <Grid item xs={2}>
            {isToBeEdited ? (
              <Button
                size="small"
                variant="contained"
                style={editButtonStyles}
                onClick={() => {
                  setIstoBeEdited(false);
                }}
              >
                Edit
              </Button>
            ) : updating ? (
              <CircularProgress size={20} />
            ) : (
              <Button
                size="small"
                variant="contained"
                style={buttonStyles}
                onClick={handleSubmitNumberOfLocations}
                disabled={!percentage || !numberOfDays}
              >
                Submit
              </Button>
            )}
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export default BalancePaymentTerms;
