/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import purple from '@material-ui/core/colors/purple';
import AddAlert from '@material-ui/icons/AddAlert';
import Snackbar from 'components/Snackbar/Snackbar.jsx';

import CircularProgress from '@material-ui/core/CircularProgress';

import AdminApprovePageStyle from 'assets/jss/material-dashboard-pro-react/views/AdminApprovePageStyle';
import uuid from 'react-uuid';
import TransporterFiles from './TransporterFiles';
import firebase from '../../config/config';
import FeatureFlagToastAlert from '../Components/FeatureFlagToastAlert';

const db = firebase.firestore();
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');

class AdminApproveTransporter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: '',
      companyList: {},
      pendingTransporters: [],
      isLoading: false,
      isButtonLoading: [],
      tc: false,
      agentName: '',
      color: 'info',
      featureFlagFiles: '',
    };
    this.approveTransporter = this.approveTransporter.bind(this);
    this.rejectTransporter = this.rejectTransporter.bind(this);
    this.getPendingTransporters = this.getPendingTransporters.bind(this);
  }

  componentDidMount() {
    this.getPendingTransporters();
    this.getFeatureFlagStatus();
  }

  getFeatureFlagStatus() {
    db.collection('FeatureFlags')
      .doc('FileUploadsFeature')
      .onSnapshot(doc => {
        if (doc.exists) {
          const data = doc.data();
          this.setState({ featureFlagFiles: data?.transporterFiles });
        }
      });
  }

  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  /**
   * will return All the Pending Transporters
   * @function
   */
  getPendingTransporters() {
    const pendingTransportersQuery = db
      .collection('Transporters')
      .where('status', '==', 'pending')
      .get();
    const pendingEditTransportersQuery = db
      .collection('Transporters')
      .where('editPending', '==', true)
      .get();

    Promise.all([pendingTransportersQuery, pendingEditTransportersQuery]).then(
      pending => {
        const pendingTransporters = [];
        pending[0].forEach(pendingTransporter => {
          pendingTransporters.push(pendingTransporter.data());
        });
        pending[1].forEach(pendingTransporter => {
          if (pendingTransporter.data().transporterEditData)
            pendingTransporters.push(pendingTransporter.data());
        });
        this.setState({ pendingTransporters });
      }
    );
  }

  /**
   * returns the Ui for Collapsable Card Component Details
   * @function
   */
  getContent = transporter => (
    <GridContainer>
      <GridItem xs={12} sm={12} md={8}>
        <p>
          <span>Email :</span> {transporter.email}
        </p>
      </GridItem>
      <GridItem xs={12} sm={12} md={8}>
        <p>
          <span>Contact :</span>
          {transporter.phoneNumber}
        </p>
      </GridItem>
    </GridContainer>
  );

  // Will Handle The Select Button Element
  handleSimpleSelect = (event, transporter) => {
    const { companyName } = event.target.value;
    const { companyId } = event.target.value;
    const { pendingTransporters } = this.state;
    for (const i in pendingTransporters) {
      if (pendingTransporters[i].uid === transporter.uid) {
        pendingTransporters[i].companyName = companyName;
        pendingTransporters[i].companyId = companyId;
      }
    }
    this.setState({ [event.target.name]: pendingTransporters });
  };

  /**
   * will return the Card
   * @function
   */
  getCards() {
    const { classes, activeRole } = this.props;
    const {
      pendingTransporters,
      isButtonLoading,
      featureFlagFiles,
    } = this.state;
    let author = '';
    return (
      <React.Fragment>
        {pendingTransporters.map(transporter => {
          if (transporter.editPending) {
            //  Passing the Edited Details of the transporter to the render and onClick functions.
            transporter = transporter.transporterEditData;
            if (transporter.editedByName) {
              author = transporter.editedByName;
            }
            transporter.isEdit = true;
            transporter.editPending = true;
          } else {
            transporter.isEdit = false;
            author = transporter.createdByName;
          }
          return (
            <GridItem
              xs={12}
              sm={12}
              md={4}
              lg={4}
              key={transporter.transporterId}
            >
              <Card>
                <CardHeader>
                  <h4 className={classes.cardTitle}>
                    {transporter.name} {transporter.isEdit && '(Edited)'}
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        <span>Email : </span> {transporter.email}
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        <span>Contact Person : </span>
                        {transporter.contactPerson}
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        <span>Contact : </span>
                        {transporter.phoneNumber}
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        <span>GST : </span>
                        {transporter.gstNumber || 'None'}
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        <span>PAN : </span>
                        {transporter.panNumber || 'None'}
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        <span>TAN : </span>
                        {transporter.tanNumber || 'None'}
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <p>
                        <span>Author : </span>
                        {author || 'None'}
                      </p>
                    </GridItem>
                    <GridItem xs={12}>
                      <TransporterFiles
                        transporterData={transporter}
                        activeRole={activeRole}
                        featureFlagFiles={featureFlagFiles}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  {isButtonLoading[transporter.transporterId] ? (
                    <CircularProgress
                      className={classes.progress}
                      style={{ color: purple[500] }}
                      thickness={7}
                    />
                  ) : (
                    <React.Fragment>
                      <Button
                        onClick={() =>
                          this.rejectTransporter(
                            transporter,
                            transporter.transporterId
                          )
                        }
                        className={classes.updateProfileButton}
                        disabled={featureFlagFiles === 1}
                      >
                        Reject
                      </Button>
                      <Button
                        color="success"
                        onClick={() =>
                          this.approveTransporter(
                            transporter,
                            transporter.transporterId
                          )
                        }
                        className={classes.updateProfileButton}
                        disabled={featureFlagFiles === 1}
                      >
                        Approve
                      </Button>
                    </React.Fragment>
                  )}
                </CardFooter>
              </Card>
            </GridItem>
          );
        })}
      </React.Fragment>
    );
  }

  /**
   * will return the notification snackbar
   * @param {string} place
   */
  showNotification(place, name) {
    this.setState({
      agentName: name,
    });
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 6000);
    }
  }

  /**
   * Approve Transporter
   * @function
   */
  approveTransporter(transporter, id) {
    const { isButtonLoading } = this.state;
    const { userDetails, systemDetails } = this.props;
    isButtonLoading[id] = true;
    this.setState({
      isButtonLoading,
    });

    if (transporter.isEdit) {
      const transporterRef = db.collection(`Transporters`);
      transporterRef
        .doc(`${transporter.transporterId}`)
        .update({
          ...transporter,
          editPending: false,
          modifiedAt: new Date(),
        })
        .then(() => {
          transporterRef
            .doc(`${transporter.transporterId}`)
            .collection('EditLog')
            .add({ ...transporter, status: 'approved' });
          const { pendingTransporters } = this.state;
          for (const i in pendingTransporters) {
            if (
              pendingTransporters[i].transporterId === transporter.transporterId
            ) {
              pendingTransporters.splice(i, 1);
            }
          }

          const auditBody = {
            data: {
              ...transporter,
              status: 'approved',
            },
            collection: 'Transporters',
            updatedBy: userDetails.uid,
            systemDetails,
            type: 'Approve Transporter',
            message: `${userDetails.email} approved Transporter EDIT with name ${transporter.name}`,
          };
          auditLog(auditBody);

          const NotificationMessage = `${transporter.name} Transporter Approved `;
          this.showNotification('tc', NotificationMessage);
          isButtonLoading[id] = false;
          this.setState({
            pendingTransporters,
            isButtonLoading,
            color: 'info',
          });
        })
        .catch(err => {
          const errorBody = {
            message: `${userDetails.email} tried to approve Transporter EDIT with name ${transporter.name} but FAILED`,
            status: 'fail',
            error: err,
            updatedBy: userDetails.uid,
            errorMessage: err.message,
            collection: 'Transporters',
            systemDetails,
          };
          auditLog(errorBody);
          this.setState({ isLoading: false });
        });
    } else {
      const transporterRef = db.collection(`Transporters`);
      transporterRef
        .doc(`${transporter.transporterId}`)
        .update({ status: 'approved', modifiedAt: new Date() })
        .then(() => {
          transporterRef
            .doc(`${transporter.transporterId}`)
            .collection('EditLog')
            .add({ ...transporter, status: 'approved' })
            .then(() => {
              const data = {
                orgId: transporter.transporterId,
                name: transporter.contactPerson,
                phoneNumber: transporter.phoneNumber,
                role: 'org',
                userType: 'transporter',
                userActive: true,
                customerId: uuid(),
                createdAt: new Date(),
                modifiedAt: new Date(),
              };
              db.collection('Customers')
                .doc(data.customerId)
                .set(data);
            });
          const { pendingTransporters } = this.state;
          for (const i in pendingTransporters) {
            if (
              pendingTransporters[i].transporterId === transporter.transporterId
            ) {
              pendingTransporters.splice(i, 1);
            }
          }

          const auditBody = {
            data: {
              ...transporter,
              status: 'approved',
            },
            collection: 'Transporters',
            updatedBy: userDetails.uid,
            systemDetails,
            type: 'Approve Transporter',
            message: `${userDetails.email} approved Transporter with name ${transporter.name}`,
          };
          auditLog(auditBody);

          const NotificationMessage = `${transporter.name} Transporter Approved `;
          this.showNotification('tc', NotificationMessage);
          isButtonLoading[id] = false;
          this.setState({
            pendingTransporters,
            isButtonLoading,
            color: 'info',
          });
        })
        .catch(err => {
          const errorBody = {
            message: `${userDetails.email} tried to approve Transporter with name ${transporter.name} but FAILED`,
            status: 'fail',
            error: err,
            updatedBy: userDetails.uid,
            errorMessage: err.message,
            collection: 'Transporters',
            systemDetails,
          };
          auditLog(errorBody);
          this.setState({ isLoading: false });
        });
    }
  }

  /**
   * Reject Transporter
   * @function
   */
  rejectTransporter(transporter, id) {
    const { isButtonLoading } = this.state;
    const { userDetails, systemDetails } = this.props;
    isButtonLoading[id] = true;
    this.setState({
      isButtonLoading,
    });

    if (transporter.isEdit) {
      const transporterRef = db.collection(`Transporters`);
      transporterRef
        .doc(transporter.transporterId)
        .update({
          editPending: false,
        })
        .then(() => {
          transporterRef
            .doc(`${transporter.transporterId}`)
            .collection('EditLog')
            .add({ ...transporter, status: 'rejected' });

          const { pendingTransporters } = this.state;
          for (const i in pendingTransporters) {
            if (
              pendingTransporters[i].transporterId === transporter.transporterId
            ) {
              pendingTransporters.splice(i, 1);
            }
          }

          const auditBody = {
            data: {
              ...transporter,
              status: 'rejected',
            },
            collection: 'Transporters',
            updatedBy: userDetails.uid,
            systemDetails,
            type: 'Reject Transporter',
            message: `${userDetails.email} rejected Transporter EDIT with name ${transporter.name}`,
          };
          auditLog(auditBody);

          const { isButtonLoading } = this.state;
          isButtonLoading[id] = false;
          const NotificationMessage = `${transporter.name} Transporter Rejected `;
          this.showNotification('tc', NotificationMessage);
          this.setState({
            pendingTransporters,
            isButtonLoading,
            color: 'danger',
          });
        })
        .catch(err => {
          const errorBody = {
            message: `${userDetails.email} tried to reject Transporter EDIT with name ${transporter.name} but FAILED`,
            status: 'fail',
            error: err,
            updatedBy: userDetails.uid,
            errorMessage: err.message,
            collection: 'Transporters',
            systemDetails,
          };
          auditLog(errorBody);
          isButtonLoading[id] = false;
          this.setState({ isButtonLoading });
        });
    } else {
      const transporterRef = db.collection(`Transporters`);
      transporterRef
        .doc(`${transporter.transporterId}`)
        .update({ status: 'rejected', modifiedAt: new Date() })
        .then(() => {
          transporterRef
            .doc(`${transporter.transporterId}`)
            .collection('EditLog')
            .add({ ...transporter, status: 'rejected' });
          const { pendingTransporters } = this.state;
          for (const i in pendingTransporters) {
            if (
              pendingTransporters[i].transporterId === transporter.transporterId
            ) {
              pendingTransporters.splice(i, 1);
            }
          }

          const auditBody = {
            data: {
              ...transporter,
              status: 'rejected',
            },
            collection: 'Transporters',
            updatedBy: userDetails.uid,
            systemDetails,
            type: 'Reject Transporter',
            message: `${userDetails.email} rejected Transporter with name ${transporter.name}`,
          };
          auditLog(auditBody);

          const { isButtonLoading } = this.state;
          isButtonLoading[id] = false;
          const NotificationMessage = `${transporter.name} Transporter Rejected `;
          this.showNotification('tc', NotificationMessage);
          this.setState({
            pendingTransporters,
            isButtonLoading,
            color: 'danger',
          });
        })
        .catch(err => {
          const errorBody = {
            message: `${userDetails.email} tried to reject Transporter with name ${transporter.name} but FAILED`,
            status: 'fail',
            error: err,
            updatedBy: userDetails.uid,
            errorMessage: err.message,
            collection: 'Transporters',
            systemDetails,
          };
          auditLog(errorBody);
          const { isButtonLoading } = this.state;
          isButtonLoading[id] = false;
          this.setState({ isButtonLoading });
        });
    }
  }

  render() {
    const { classes } = this.props;
    const { featureFlagFiles } = this.state;

    const { pendingTransporters, color, tc, agentName } = this.state;
    return (
      <div>
        {featureFlagFiles === 1 && <FeatureFlagToastAlert />}
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="warning">
                <p className={`${classes.cardHeader}`}>Approve Transporters</p>
                <h3 className={classes.cardTitle}>
                  {pendingTransporters.length} <small />
                </h3>
              </CardHeader>
              <CardBody className={`${classes.cardHeader}`}>
                {pendingTransporters.length <= 0 && (
                  <p className={classes.textCenter}>No Pending Transporters</p>
                )}
                <GridContainer>{this.getCards()}</GridContainer>
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats} />
              </CardFooter>
            </Card>
            <Snackbar
              place="tc"
              color={color}
              icon={AddAlert}
              message={agentName}
              open={tc}
              closeNotification={() => this.setState({ tc: false })}
              close
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

AdminApproveTransporter.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
});

export default connect(mapStateToProps)(
  withStyles(AdminApprovePageStyle)(AdminApproveTransporter)
);
