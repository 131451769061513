/* eslint-disable react/no-deprecated */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/Icons
import NoteAdd from '@material-ui/icons/NoteAdd';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Accordion from 'components/Accordion/Accordion.jsx';
import Table from 'components/Table/Table.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import DeleteForever from '@material-ui/icons/DeleteForever';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import AddAlert from '@material-ui/icons/AddAlert';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import moment from 'moment';
import firebase from '../../../config/config';

import {
  switchActivePage,
  setCurrentTransaction,
  setReceivableBthTransactions,
} from '../../../reducers/transactions';

const db = firebase.firestore();
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');

class BthHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      payableTransactions: [
        {
          color: 'info',
          data: ['28-09-2019', '10,000', 'message is'],
        },
      ],
      amount: '',
      amountState: '',
      message: '',
      messageState: '',
      isBthForm: false,
      tc: false,
      isLoading: false,
      startDate: moment(),
      date: moment().format('MMM Do YY'),
      alert: null,
    };
    this.hideDetailedForm = this.hideDetailedForm.bind(this);
    this.showBthForm = this.showBthForm.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onConfirmAlert = this.onConfirmAlert.bind(this);
    this.onCancelAlert = this.onCancelAlert.bind(this);
  }

  componentDidMount() {
    const {
      classes,
      currentTransactionData,
      userDetails,
      activeRole,
    } = this.props;
    const bthRef = db
      .collection('Transactions')
      .doc(currentTransactionData.transactionId)
      .collection('Receivable')
      .where('type', '==', 'BTH');
    bthRef.orderBy('createdAt', 'asc').onSnapshot(bthRes => {
      const bthTransactions = [];
      bthRes.forEach(element => {
        if (
          userDetails.isAdmin &&
          !currentTransactionData.isEditedTransaction &&
          currentTransactionData.status === 'approved' &&
          !element.data().void
        ) {
          const date = moment(element.data().date, 'MMM Do YY').format(
            'DD/MM/YY'
          );
          bthTransactions.push({
            color: 'info',
            data: [
              date,
              Intl.NumberFormat('en-IN').format(element.data().amount),
              element.data().message,
              activeRole === 'finance' ? element.data().createdByName : '',
              <Button
                color="danger"
                className={classes.actionButton}
                key={element.data().transactionId}
                onClick={() => this.delete(element.id, element.data())}
                justIcon
              >
                <DeleteForever className={classes.icon} />
              </Button>,
            ],
          });
        } else {
          const date = moment(element.data().date, 'MMM Do YY').format(
            'DD/MM/YY'
          );
          bthTransactions.push({
            color: 'info',
            data: [
              // element.data().date,
              date,
              Intl.NumberFormat('en-IN').format(element.data().amount),
              element.data().message,
              activeRole === 'finance' ? element.data().createdByName : '',
              ' ',
            ],
            void: element.data().void,
          });
        }
      });
      this.props.setReceivableBthTransactions(bthTransactions);
    });
  }

  delete(id, data) {
    const { classes } = this.props;
    this.setState({
      alert: (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, Void it!"
          cancelBtnBsStyle="success"
          confirmBtnBsStyle="warning"
          title="Do you want to void the entry?"
          onConfirm={() => this.onConfirmAlert(id, data)}
          onCancel={() => this.onCancelAlert()}
          confirmBtnCssClass={`${classes.button} ${classes.warning}`}
          cancelBtnCssClass={`${classes.button} ${classes.success}`}
        />
      ),
    });
  }

  onConfirmAlert(id, data) {
    const { classes } = this.props;
    this.setState({
      alert: (
        <SweetAlert
          error
          showCancel
          confirmBtnText="Yes, Void it!"
          cancelBtnBsStyle="success"
          confirmBtnBsStyle="danger"
          title="Are you sure you want to void this entry?"
          onConfirm={() => this.deleteReceivableAth(id, data)}
          onCancel={() => this.onCancelAlert()}
          confirmBtnCssClass={`${classes.button} ${classes.danger}`}
          cancelBtnCssClass={`${classes.button} ${classes.success}`}
        />
      ),
    });
  }

  deleteReceivableAth(id, data) {
    const {
      userDetails,
      systemDetails,
      currentTransactionData,
      classes,
    } = this.props;
    this.setState({
      alert: (
        <SweetAlert showConfirm={false} error title="Voiding ...">
          <CircularProgress
            className={classes.progress}
            style={{ color: purple[500] }}
            thickness={7}
          />
        </SweetAlert>
      ),
    });
    const body = {
      isPayable: false,
      isReceivable: true,
      receivableId: id,
      payableId: '',
      transactionId: data.transactionId,
    };
    const deleteEntry = firebase
      .functions()
      .httpsCallable('transactions-deleteEntry');
    deleteEntry(body)
      .then(() => {
        const auditBody = {
          data,
          collection: 'Payable',
          updatedBy: userDetails.uid,
          systemDetails,
          type: 'Finance Entry',
          message: `${userDetails.email} Voided BTH Receivable Entry Rs.${data.amount} for Transaction ${currentTransactionData.transactionSerial}`,
        };
        auditLog(auditBody);
        this.setState({
          alert: (
            <SweetAlert
              success
              title="Entry voided successfully!"
              onConfirm={() => this.onCancelAlert()}
              onCancel={() => this.onCancelAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
              cancelBtnCssClass={`${classes.button} ${classes.success}`}
            />
          ),
        });
      })
      .catch(err => {
        this.setState({
          alert: (
            <SweetAlert
              error
              title="Oops, something went wrong"
              onConfirm={() => this.onCancelAlert()}
              onCancel={() => this.onCancelAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
              cancelBtnCssClass={`${classes.button} ${classes.success}`}
            />
          ),
        });
      });
  }

  onCancelAlert() {
    this.setState({
      alert: null,
    });
  }

  handleChange(date) {
    this.setState({
      startDate: date,
      date: moment(date).format('MMM Do YY'),
    });
  }

  hideDetailedForm() {
    this.props.switchActivePage(false);
  }

  /**
   * will return the notification snackbar
   * @param {string} place
   */
  showNotification(place) {
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 5000);
    }
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        this.setState({
          [`${stateName}State`]: 'success',
          [stateName]: event.target.value,
        });
        break;
    }
  }

  /**
   * Will Activate Form for Adding New Payable Transaction
   * @function
   */
  showBthForm() {
    this.setState({
      isBthForm: !this.state.isBthForm,
    });
  }

  /**
   * returns the Ui for Collapsable Card Component Details
   * @function
   */
  getContent = () => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Accordion
            active={1}
            collapses={[
              { title: `Transactions : 21`, content: this.getTable() },
            ]}
          />
          <GridContainer>
            {this.state.isBthForm ? (
              <GridItem xs={12} sm={12} md={12}>
                {this.getForm()}
              </GridItem>
            ) : (
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  color="info"
                  className={classes.updateProfileButton}
                  onClick={() => this.showBthForm()}
                >
                  <NoteAdd />
                  Add More
                </Button>
              </GridItem>
            )}
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} />
      </GridContainer>
    );
  };

  getForm() {
    const { classes } = this.props;
    return (
      <Card
        style={{ marginTop: '2px', paddingBottom: '1px', marginBottom: '5px' }}
      >
        <CardBody style={{ marginBottom: '1px', paddingBottom: '1px' }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Amount"
                id="trucker"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: this.state.amount,
                  onChange: event => this.change(event, 'amount', 'number'),
                  type: 'number',
                }}
                success={this.state.amountState === 'success'}
                error={this.state.amountState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Narration"
                id="trucker"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: this.state.message,
                  onChange: event => this.change(event, 'message', 'length', 2),
                  type: 'length',
                }}
                success={this.state.messageState === 'success'}
                error={this.state.messageState === 'error'}
              />
            </GridItem>
            <DatePicker
              className={classes.datePicker}
              selected={this.state.startDate}
              onChange={this.handleChange}
            />
          </GridContainer>
        </CardBody>
        <CardFooter>
          {this.state.isLoading ? (
            <CircularProgress
              className={classes.progress}
              style={{ color: purple[500] }}
              thickness={7}
            />
          ) : (
            <React.Fragment>
              <Button
                className={classes.updateProfileButton}
                onClick={() => this.showBthForm()}
              >
                Cancel
              </Button>
              <Button
                color="info"
                className={classes.updateProfileButton}
                onClick={() => this.submitBth()}
              >
                Submit
              </Button>
            </React.Fragment>
          )}
        </CardFooter>
      </Card>
    );
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable() {
    const { classes, activeRole } = this.props;
    return (
      <Card
        style={{ marginTop: '2px', paddingBottom: '1px', marginBottom: '5px' }}
      >
        {this.props.bthTransactions.length > 0 ? (
          <CardBody className={classes.customCardContentClass}>
            <Table
              hover
              tableHead={[
                'Date',
                'Amount',
                'Narration',
                activeRole === 'finance' ? 'Created By' : '',
              ]}
              tableData={this.props.bthTransactions}
            />
          </CardBody>
        ) : (
          <p
            className={classes.textCenter}
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            No Transactions Available
          </p>
        )}
      </Card>
    );
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify  Amount
      if (this.verifyNumber(this.state.amount)) {
        this.setState({
          amountState: 'success',
        });
      } else {
        this.setState({ amountState: 'error' });
        error = true;
      }

      // Verify  message
      if (this.verifyLength(this.state.message, 2)) {
        this.setState({
          messageState: 'success',
        });
      } else {
        this.setState({ messageState: 'error' });
        error = true;
      }
      // Verify whether the amount is less the currentReceivableAmount
      if (
        parseInt(this.state.amount, 10) >
        parseInt(this.props.currentReceivableAmount, 10)
      ) {
        this.setState({
          amountState: 'error',
        });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        reject('validation Failed');
      }
    });
  }

  /**
   * Will Submit the Payable history to Database
   * @function
   */
  submitBth = () => {
    const { userDetails, systemDetails, currentTransactionData } = this.props;
    const { amount, message, date } = this.state;

    this.setState({
      isLoading: true,
    });
    this.validateAllFields()
      .then(
        success => {
          if (success) {
            db.collection('Transactions')
              .doc(currentTransactionData.transactionId)
              .collection('Receivable')
              .add({
                amount,
                message,
                createdAt: new Date(),
                createdById: userDetails.uid,
                createdByName: userDetails.name,
                type: 'BTH',
                transactionId: currentTransactionData.transactionId,
                date,
              })
              .then(receivableResult => {
                const auditBody = {
                  data: {
                    amount,
                    message,
                    type: 'BTH',
                    transactionId: currentTransactionData.transactionId,
                    date,
                  },
                  collection: 'Receivable',
                  updatedBy: userDetails.uid,
                  systemDetails,
                  type: 'Finance Entry',
                  message: `${userDetails.email} added Receivable Entry Rs.${amount} BTH for Transaction ${currentTransactionData.transactionSerial}`,
                };
                auditLog(auditBody);

                this.showNotification('tc');
                this.clearForm();
              })
              .catch(err => {
                const errorBody = {
                  message: `${userDetails.email} tried to add Receivable Entry Rs.${amount} BTH for Transaction ${currentTransactionData.transactionSerial} but FAILED`,
                  status: 'fail',
                  error: err,
                  updatedBy: userDetails.uid,
                  errorMessage: err.message,
                  collection: 'Receivable',
                  systemDetails,
                };
                auditLog(errorBody);
              });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        },
        err => {
          this.setState({
            isLoading: false,
          });
        }
      )
      .catch(error => {
        this.setState({
          isLoading: false,
        });
      });
  };

  /**
   * will clear the form
   * @function
   */
  clearForm() {
    this.showBthForm();
    this.setState({
      amount: '',
      message: '',
      amountState: '',
      messageState: '',
      isLoading: false,
    });
  }

  render() {
    const { classes, activeRole, currentTransactionData } = this.props;
    const { alert, isBthForm, tc } = this.state;
    return (
      <GridContainer>
        {alert}
        <GridItem xs={12} sm={12} md={12}>
          {this.getTable()}
          <GridContainer>
            {isBthForm ? (
              <GridItem xs={12} sm={12} md={12}>
                {this.getForm()}
              </GridItem>
            ) : (
              <GridItem xs={12} sm={12} md={12}>
                {(activeRole === 'finance' ||
                  activeRole === 'finance-recievable') &&
                !currentTransactionData.void ? (
                  <Button
                    color="info"
                    className={classes.updateProfileButton}
                    onClick={() => this.showBthForm()}
                  >
                    <NoteAdd />
                    Add More
                  </Button>
                ) : null}
              </GridItem>
            )}
          </GridContainer>
        </GridItem>
        <Snackbar
          place="tc"
          color="info"
          icon={AddAlert}
          message="BTH History Added Successfully"
          open={tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
        <GridItem xs={12} sm={12} md={12} />
      </GridContainer>
    );
  }
}

BthHistory.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  // currentTransactionData: state.transactions.currentTransactionData,
  transactions: state.transactions.transactions,
  payableTransactions: state.transactions.payableTransactions,
  athTransactions: state.transactions.athTransactions,
  bthTransactions: state.transactions.receivableBthTransactions,
  currentReceivableAmount: state.transactions.currentReceivableAmount,
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setCurrentTransaction,
      setReceivableBthTransactions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(BthHistory));
