/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
// @material-ui/icons

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import ArrowBack from '@material-ui/icons/ArrowBack';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import SweetAlert from 'react-bootstrap-sweetalert';

// Fireabse
import userProfileStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Button as MUIButton,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import swal from 'sweetalert';
import Popup from 'reactjs-popup';
import moment from 'moment';
import CommentSection from './Section/CommentSection';
import firebase from '../../config/config';
import LRUpload from './Section/LRUpload';
import GSTFileUpload from './Section/GSTFileUpload';
import PANFileUpload from './Section/PANFileUpload';
import AdhaarFileUpload from './Section/AdhaarFileUpload';
import OverAllRating from './Ratings/OverAllRating';
import SecondaryContactsView from './SecondaryContacts/SecondaryContactsView';
import TransporterNewFieldsView from './Section/TransporterNewFields/TransporterNewFieldsView';
import AdhaarBackFileUploader from './Section/AdhaarBackFileUpload';
import TransporterGTA from './Section/TransporterGTA';
import auditLogger from '../../utils/auditLoggerToPG';
import FeatureFlagToastAlert from '../Components/FeatureFlagToastAlert';
import history from '../../history';

const contentStyle = { width: '300px' };

const deleteModalButton = {
  width: '45%',
  background: '#52BE56',
  borderRadius: 0,
  color: 'white',
  margin: '5px',
};

const cancelButton = {
  width: '45%',
  background: 'lightgray',
  borderRadius: 0,
  margin: '5px',
};

const { REACT_APP_API_BASE_URL, REACT_APP_OFFICE_REPORTS_API } = process.env;
const db = firebase.firestore();
const buttonStyles = {
  background: '#3366FF',
  color: 'white',
  fontSize: '12px',
  textTransform: 'capitalize',
  marginLeft: '20px',
};
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');
class AddTransporters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameState: '',

      email: '',
      emailState: '',

      contactPerson: '',

      phoneNumber: '',
      phoneNumberState: '',

      gstNumber: '',
      gstNumberState: '',

      panNumber: '',
      panNumberState: '',

      tanNumber: '',
      tanNumberState: '',

      isLoading: false,

      selectedTransporterData: null,
      componentLoading: false,
      showDetails: false,

      alert: null,
      edit: false,
      blacklisting: false,
      validationMessage: '',
      disableSubmit: false,
      validatingPan: false,
      presentOrgs: [],
      transporterTDSChecked: '',
      transporterGSTChecked: '',
      tdsDeductionChanged: false,
      gstChanged: false,
      isUpdatingTDS: false,
      isUpdatingGST: false,
      featureFlagFiles: '',
    };
    this.change = this.change.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleGstCheck = this.handleGstCheck.bind(this);
    this.submitTransporter = this.submitTransporter.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.getTransporterData = this.getTransporterData.bind(this);
  }

  componentDidMount() {
    this.setState({
      componentLoading: true,
    });
    // eslint-disable-next-line react/no-find-dom-node
    ReactDOM.findDOMNode(this).scrollIntoView();

    // eslint-disable-next-line react/no-find-dom-node
    const TransporterId = history.location.state;

    const handleGetTransporterDetails = async id => {
      const docRef = await db.collection('Transporters').doc(id);
      docRef
        .get()
        .then(doc => {
          if (doc.exists) {
            const data = doc.data();
            this.setState(
              {
                selectedTransporterData: data,
                componentLoading: false,
                showDetails: true,
              },
              () => {
                this.getTransporterData();
                this.auditLog(data);
              }
            );
          }
        })
        .catch(error => error);
    };
    handleGetTransporterDetails(TransporterId);
  }

  getTransporterData() {
    const { selectedTransporterData } = this.state;
    const {
      name,
      contactPerson,
      phoneNumber,
      email,
      gstNumber,
      panNumber,
      tdsDeduction,
      gstBilling,
    } = selectedTransporterData;
    let { tanNumber } = selectedTransporterData;
    if (tanNumber === undefined) tanNumber = '';
    this.getFeatureFlagStatus();
    this.setState({
      name,
      contactPerson,
      phoneNumber,
      email,
      gstNumber,
      panNumber,
      tanNumber,
    });
    if (tdsDeduction) {
      this.setState({ transporterTDSChecked: tdsDeduction });
    }
    if (gstBilling) {
      this.setState({ transporterGSTChecked: gstBilling });
    } else this.setState({ transporterGSTChecked: 'false' });
  }

  getFeatureFlagStatus() {
    let data;
    db.collection('FeatureFlags')
      .doc('FileUploadsFeature')
      .onSnapshot(doc => {
        if (doc.exists) {
          const data = doc.data();
          this.setState({ featureFlagFiles: data?.transporterFiles });
        }
      });
  }

  auditLog = async data => {
    const userDetails = firebase.auth().currentUser;
    const { activeRole } = this.props;

    const auditBody = {
      type: 'view',
      entity: 'Transporter',
      collection: 'Transporters',
      transporterViewedBy: userDetails.uid,
      email: userDetails.email,
      role: activeRole,
      message: `${userDetails.displayName} with email ${userDetails.email} and role ${activeRole} tried to view ${data.name} transporter with ${data.truckerId} as transporter ID`,
      viewedAt: moment().format('llll'),
    };
    await auditLog(auditBody);
    const systemDetails = JSON.parse(localStorage.getItem('systemDetails'));
    const auditPayload = {
      collection: 'transporter',
      orgId: null,
      data: {},
      message: `${userDetails?.displayName} with email id ${userDetails?.email} viewed a transporter ${data?.name} `,
      systemDetails,
      type: `view Transporter`,
      role: `${this.capitalizeFirstLetter(activeRole?.split('is')[0])}`,
      eventDateTime: new Date().toISOString(),
      source: 'office',
    };
    auditLogger(auditPayload);
  };

  // eslint-disable-next-line react/sort-comp
  hideAlert() {
    this.setState({
      alert: null,
    });
    const { activeRole } = this.props;
    if (activeRole == 'ops') {
      history.push(`/${activeRole}/transporters`);
    } else if (
      activeRole == 'sales' ||
      activeRole == 'traffic' ||
      activeRole == 'branch-ops'
    ) {
      history.push(`/${activeRole}/transporters/view`);
    }
  }

  // function that returns true if value is there, false otherwise
  verifyRequired(value) {
    if (value) {
      return true;
    }
    return false;
  }

  // function that returns true if value is Valid GST, false otherwise
  verifyGst(value) {
    const gstRex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (gstRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  async validatePanNumber(pan) {
    this.setState({ validatingPan: true });

    const endPoint = 'pipe/pan-validate';
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const token = Aa;
    const body = {
      type: 'transporter',
      pan,
    };
    const response = await axios.post(
      `${REACT_APP_API_BASE_URL}${endPoint}`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const duplicate = response?.data?.Info.pan_match;
    if (duplicate) {
      this.setState({
        panNumberState: 'error',
        validationMessage: 'PAN Number already exists',
        disableSubmit: true,
        validatingPan: false,
      });
    } else {
      this.setState({
        panNumberState: 'success',
        disableSubmit: false,
        validatingPan: false,
      });
    }
  }

  async checkIfMultipleOrgs(number) {
    this.setState({ validatingPhone: true });
    let status = false;
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const token = Aa;
    try {
      const endPoint = 'search-office-user';
      const body = {
        type: 'transporters',
        // pan,
        contact_person: '',
        phone_number: number,
        name: '',
        limit: 10,
        offset: 0,
      };
      const response = await axios.post(
        `${REACT_APP_OFFICE_REPORTS_API}${endPoint}`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = response.data.body;

      if (data.length > 0) {
        this.setState({ presentOrgs: data });
      } else {
        status = true;
      }
      this.setState({ validatingPhone: false });
    } catch (e) {
      status = true;
      this.setState({ validatingPhone: false });
    }
    return status;
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPan(value) {
    const regpan = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    if (regpan.test(value)) {
      this.validatePanNumber(value);
    }
    return false;
  }

  // function that verifies if value contains Tan
  static verifyTan(value) {
    const regtan = /(^([a-zA-Z]{4})([0-9]{5})([a-zA-Z]{1})$)/;
    if (regtan.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPhone(value) {
    const regPhone = /^[6789]\d{9}$/;
    if (regPhone.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'required':
        if (this.verifyRequired(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'pan':
        if (this.verifyPan(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
            disableSubmit: false,
          });
        }
        break;
      case 'phone':
        if (this.verifyPhone(event.target.value)) {
          this.checkIfMultipleOrgs(event.target.value);
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'gst':
        if (this.verifyGst(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'tan':
        if (AddTransporters.verifyTan(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Name
      if (this.verifyLength(this.state.name, 1)) {
        this.setState({
          nameState: 'success',
        });
      } else {
        this.setState({
          nameState: 'error',
        });
        error = true;
      }

      // Verify Phone Number

      if (!error) {
        resolve(true);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('validation Failed');
      }
    });
  }

  async blacklistTrucker(id) {
    const { activeRole } = this.props;
    this.setState({ blacklisting: true });
    const systemDetails = JSON.parse(localStorage.getItem('systemDetails'));

    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const token = Aa;
    try {
      const endPoint = 'pipe/add-blacklist';
      const body = {
        id,
        type: 'transporter',
        role: `is${this.capitalizeFirstLetter(
          activeRole === 'branch-ops' ? 'branchOps' : activeRole.split('is')[0]
        )}`,
      };
      await axios.post(`${REACT_APP_API_BASE_URL}${endPoint}`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });
      this.setState({ blacklisting: false });
      const auditPayload = {
        collection: 'transporter',
        orgId: id,
        data: body,
        message: `${userDetails?.displayName} with email id ${userDetails?.email} blacklisted a transporter`,
        systemDetails,
        type: `Blacklist Transporter`,
        role: `${this.capitalizeFirstLetter(
          activeRole === 'branch-ops' ? 'branchOps' : activeRole?.split('is')[0]
        )}`,
        eventDateTime: new Date().toISOString(),
        source: 'office',
      };
      auditLogger(auditPayload);
      swal(
        'Blacklisted Transporter!',
        'you have blacklisted transporter successfully!',
        'success'
      );
    } catch (e) {
      this.setState({ blacklisting: false });
    }
  }

  capitalizeFirstLetter(str) {
    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
  }

  submitPendingEdit() {
    const {
      name,
      email,
      contactPerson,
      phoneNumber,
      gstNumber,
      panNumber,
      tanNumber,
    } = this.state;
    const {
      userDetails,
      // systemDetails,
      activeRole,
    } = this.props;
    const { selectedTransporterData } = this.state;
    const transporterEditData = {
      ...selectedTransporterData,
      name,
      email,
      contactPerson,
      phoneNumber,
      gstNumber,
      panNumber,
      tanNumber,
      editedBy: userDetails.uid,
      editedByName: userDetails.name,
      modifiedAt: new Date(),
    };
    const systemDetails = JSON.parse(localStorage.getItem('systemDetails'));
    const transporterRef = db.collection(`Transporters`);
    transporterRef
      .doc(selectedTransporterData.transporterId)
      .update({
        transporterEditData,
        editPending: true,
        editedBy: userDetails.name,
      })
      .then(userDoc => {
        const auditBody = {
          data: {
            name,
            email,
            contactPerson,
            phoneNumber,
            gstNumber,
            panNumber,
            tanNumber,
          },
          collection: 'Transporters',
          editedBy: userDetails.name,
          systemDetails,
          type: 'Edit Transporter',
          message: `${userDetails.email} edited Transporter with name ${name}`,
        };
        auditLog(auditBody);
        const auditPayload = {
          collection: 'transporter',
          orgId: selectedTransporterData.transporterId,
          data: {
            name,
            email,
            contactPerson,
            phoneNumber,
            gstNumber,
            panNumber,
            tanNumber,
            editedBy: userDetails.uid,
            editedByName: userDetails.name,
            modifiedAt: new Date(),
          },
          message: `${userDetails?.name} with email id ${userDetails?.email} editted a transporter `,
          systemDetails,
          type: `Edit Transporter`,
          role: `${this.capitalizeFirstLetter(
            activeRole === 'branch-ops'
              ? 'branchOps'
              : activeRole?.split('is')[0]
          )}`,
          eventDateTime: new Date().toISOString(),
          source: 'office',
        };
        auditLogger(auditPayload);
        this.clearForm();
        this.setState({ isLoading: false });

        // Updated Succeessfully
      })
      .catch(err => {
        const errorBody = {
          message: `${userDetails.email} tried to edit Transporter with name ${name} but FAILED`,
          status: 'fail',
          error: err,
          updatedBy: userDetails.uid,
          errorMessage: err.message,
          collection: 'Transporters',
          systemDetails,
        };
        auditLog(errorBody);
      });
  }

  /**
   * Submits the transporter details to the Database
   * @function
   */
  submitTransporter() {
    const self = this;
    this.setState({
      isLoading: true,
    });
    const {
      userDetails,
      // systemDetails,
      activeRole,
    } = this.props;
    const { selectedTransporterData } = this.state;
    const {
      name,
      email,
      contactPerson,
      phoneNumber,
      gstNumber,
      panNumber,
      tanNumber,
      transporterTDSChecked,
      transporterGSTChecked,
    } = this.state;
    const systemDetails = JSON.parse(localStorage.getItem('systemDetails'));
    this.validateAllFields()
      .then(
        success => {
          if (success) {
            if (activeRole === 'sales' || activeRole === 'traffic')
              this.submitPendingEdit();
            else {
              const transporterRef = db.collection(`Transporters`);
              transporterRef
                .doc(selectedTransporterData.transporterId)
                .update({
                  name,
                  email,
                  contactPerson,
                  phoneNumber,
                  gstNumber,
                  panNumber,
                  tanNumber,
                  editedBy: userDetails.uid,
                  editedByName: userDetails.name,
                  modifiedAt: new Date(),
                  tdsDeduction:
                    transporterTDSChecked === 'none' ||
                    transporterTDSChecked === ''
                      ? null
                      : transporterTDSChecked,
                  gstBilling: transporterGSTChecked,
                })
                .then(userDoc => {
                  const auditBody = {
                    data: {
                      name,
                      email,
                      contactPerson,
                      phoneNumber,
                      gstNumber,
                      panNumber,
                      tanNumber,
                    },
                    collection: 'Transporters',
                    updatedBy: userDetails.uid,
                    systemDetails,
                    type: 'Edit Transporter',
                    message: `${userDetails.email} edited Transporter with name ${name}`,
                  };
                  auditLog(auditBody);

                  const auditPayload = {
                    collection: 'transporter',
                    orgId: selectedTransporterData.transporterId,
                    data: {
                      name,
                      email,
                      contactPerson,
                      phoneNumber,
                      gstNumber,
                      panNumber,
                      tanNumber,
                      editedBy: userDetails.uid,
                      editedByName: userDetails.name,
                      modifiedAt: new Date(),
                    },
                    message: `${userDetails?.name} with email id ${userDetails?.email} editted a transporter with id ${selectedTransporterData.transporterId}`,
                    systemDetails,
                    type: `Edit Transporter`,
                    role: `${this.capitalizeFirstLetter(
                      activeRole === 'branch-ops'
                        ? 'branchOps'
                        : activeRole?.split('is')[0]
                    )}`,
                    eventDateTime: new Date().toISOString(),
                    source: 'office',
                  };
                  auditLogger(auditPayload);
                  // Updated Succeessfully
                  this.clearForm();
                  this.setState({ isLoading: false });
                })
                .catch(err => {
                  const errorBody = {
                    message: `${userDetails.email} tried to edit Transporter with name ${name} but FAILED`,
                    status: 'fail',
                    error: err,
                    updatedBy: userDetails.uid,
                    errorMessage: err.message,
                    collection: 'Transporters',
                    systemDetails,
                  };
                  auditLog(errorBody);
                });
            }
          }
        },
        err => {
          console.log(err);
          self.setState({
            isLoading: false,
          });
        }
      )
      .catch(err => {
        console.log(err);
        self.setState({
          isLoading: false,
        });
      });
  }

  handleCheck = event => {
    // setValue(event.target.value);
    this.setState({
      transporterTDSChecked: event.target.value,
      tdsDeductionChanged: true,
    });
  };

  handleGstCheck = event => {
    // setValue(event.target.value);
    this.setState({
      transporterGSTChecked: event.target.value,
      gstChanged: true,
    });
  };

  handleSubmitTDS = () => {
    this.setState({ isUpdatingTDS: true });
    const { activeRole } = this.props;
    const { transporterTDSChecked } = this.state;
    const { userDetails, systemDetails } = this.props;
    const { selectedTransporterData } = this.state;
    const transporterRef = db.collection(`Transporters`);
    transporterRef
      .doc(selectedTransporterData.transporterId)
      .update({
        editedBy: userDetails.uid,
        editedByName: userDetails.name,
        modifiedAt: new Date(),
        tdsDeduction:
          transporterTDSChecked === '' ? null : transporterTDSChecked,
      })
      .then(userDoc => {
        const auditBody = {
          data: {
            editedBy: userDetails.uid,
            editedByName: userDetails.name,
            modifiedAt: new Date(),
            tdsDeduction:
              transporterTDSChecked === 'none' || transporterTDSChecked === ''
                ? null
                : transporterTDSChecked,
          },
          collection: 'Transporters',
          updatedBy: userDetails.uid,
          systemDetails,
          type: 'Edit Transporter',
          message: `${userDetails.email} edited Transporter with name ${selectedTransporterData.name}`,
        };
        auditLog(auditBody);
        const auditPayload = {
          collection: 'Transactions',
          orgId: selectedTransporterData?.transporterId,
          data: {
            editedBy: userDetails.uid,
            editedByName: userDetails.name,
            modifiedAt: new Date(),
            tdsDeduction:
              transporterTDSChecked === 'none' || transporterTDSChecked === ''
                ? null
                : transporterTDSChecked,
          },
          message: `${userDetails.email} edited Transporter TDS Decution for transporter with name ${selectedTransporterData.name}`,
          systemDetails,
          type: 'TDS Deduction Update',
          role: `${this.capitalizeFirstLetter(
            activeRole === 'branch-ops'
              ? 'branchOps'
              : activeRole?.split('is')[0]
          )}`,
          eventDateTime: new Date().toISOString(),
          source: 'office',
        };
        auditLogger(auditPayload);
        // Updated Succeessfully
        this.setState({
          alert: (
            <SweetAlert
              success
              style={{ display: 'block', marginTop: '-100px' }}
              title="Good job!"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
            >
              TDS Deduction updated successfully
            </SweetAlert>
          ),
        });
        this.setState({ isUpdatingTDS: false });
      })
      .catch(err => {
        const errorBody = {
          message: `${userDetails.email} tried to edit Transporter with name ${selectedTransporterData.name} but FAILED`,
          status: 'fail',
          error: err,
          updatedBy: userDetails.uid,
          errorMessage: err.message,
          collection: 'Transporters',
          systemDetails,
        };
        auditLog(errorBody);
        this.setState({ isUpdatingTDS: false });
      });
  };

  handleSubmitGST = () => {
    this.setState({ isUpdatingGST: true });
    const { activeRole } = this.props;
    const { transporterGSTChecked } = this.state;
    const { userDetails, systemDetails } = this.props;
    const { selectedTransporterData } = this.state;
    const transporterRef = db.collection(`Transporters`);
    transporterRef
      .doc(selectedTransporterData.transporterId)
      .update({
        editedBy: userDetails.uid,
        editedByName: userDetails.name,
        modifiedAt: new Date(),
        gstBilling: transporterGSTChecked,
      })
      .then(userDoc => {
        const auditBody = {
          data: {
            editedBy: userDetails.uid,
            editedByName: userDetails.name,
            modifiedAt: new Date(),
            gstBilling: transporterGSTChecked,
          },
          collection: 'Transporters',
          updatedBy: userDetails.uid,
          systemDetails,
          type: 'Edit Transporter',
          message: `${userDetails.email} edited Transporter with name ${selectedTransporterData.name}`,
        };
        auditLog(auditBody);
        const auditPayload = {
          collection: 'Transactions',
          orgId: selectedTransporterData?.transporterId,
          data: {
            editedBy: userDetails.uid,
            editedByName: userDetails.name,
            modifiedAt: new Date(),
            gstBilling: transporterGSTChecked,
          },
          message: `${userDetails.email} edited Transporter GST Billing for transporter with name ${selectedTransporterData.name}`,
          systemDetails,
          type: 'GST Billing Update',
          role: `${this.capitalizeFirstLetter(
            activeRole === 'branch-ops'
              ? 'branchOps'
              : activeRole?.split('is')[0]
          )}`,
          eventDateTime: new Date().toISOString(),
          source: 'office',
        };
        auditLogger(auditPayload);
        // Updated Succeessfully
        this.setState({
          alert: (
            <SweetAlert
              success
              style={{ display: 'block', marginTop: '-100px' }}
              title="Good job!"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
            >
              GST Billing updated successfully
            </SweetAlert>
          ),
        });
        this.setState({ isUpdatingGST: false });
      })
      .catch(err => {
        const errorBody = {
          message: `${userDetails.email} tried to edit Transporter with name ${selectedTransporterData.name} but FAILED`,
          status: 'fail',
          error: err,
          updatedBy: userDetails.uid,
          errorMessage: err.message,
          collection: 'Transporters',
          systemDetails,
        };
        auditLog(errorBody);
        this.setState({ isUpdatingGST: false });
      });
  };

  /**
   * Will Clears ALl the State values after form submision
   * @function
   */
  clearForm() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Good job!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
        >
          Transporter Edited Succeessfully
        </SweetAlert>
      ),
    });
    const self = this;
    self.setState({
      name: '',
      nameState: '',
      email: '',
      emailState: '',
      contactPerson: '',
      contactPersonState: '',
      phoneNumber: '',
      phoneNumberState: '',
      creditLimit: '',
      creditLimitState: '',
      gstNumber: '',
      gstNumberState: '',
      panNumber: '',
      panNumberState: '',
      tanNumber: '',
      tanNumberState: '',
      city: '',
      cityState: '',
      state: '',
      stateState: '',
      postalCode: '',
      postalCodeState: '',
    });
  }

  render() {
    const {
      classes,
      // userDetails,
      activeRole,
    } = this.props;
    const { featureFlagFiles, componentLoading, showDetails } = this.state;
    return (
      <div>
        {featureFlagFiles === 1 && <FeatureFlagToastAlert />}
        {componentLoading ? (
          <GridContainer justify="center">
            <CircularProgress
              className={classes.progress}
              style={{ color: purple[500] }}
              thickness={3}
            />
          </GridContainer>
        ) : (
          showDetails && (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {this.state.alert}
                <Card>
                  <CardHeader
                    className={`${classes.cardHeader}`}
                    color="warning"
                  >
                    <CardIcon
                      color="primary"
                      className={`${classes.cardHeader}`}
                      onClick={() => this.hideAlert()}
                    >
                      <ArrowBack />
                    </CardIcon>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <h4 className={classes.cardIconTitle}>
                        Add Transporter
                        <small />
                      </h4>
                      {this.state.blacklisting ? (
                        <CircularProgress />
                      ) : activeRole === 'ops' ? (
                        <Popup
                          trigger={
                            this.state.blacklisting ? (
                              <CircularProgress
                                style={{ color: '#FCD21F', margin: '5px' }}
                                size={30}
                                thickness={8}
                              />
                            ) : (
                              <Button
                                style={{
                                  background: 'black',
                                  color: '#FCD21F',
                                  fontWeight: 'bold',
                                }}
                              >
                                Blacklist
                              </Button>
                            )
                          }
                          {...{
                            contentStyle,
                          }}
                          modal
                        >
                          {close => (
                            <div
                              style={{
                                padding: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <HighlightOffIcon
                                fontSize="large"
                                color="error"
                              />

                              <h4 style={{ fontWeight: 'bold' }}>
                                Are You Sure?
                              </h4>
                              <p>
                                If you proceed, you will not be able to recover
                                it
                              </p>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                }}
                              >
                                <Button onClick={close} style={cancelButton}>
                                  No
                                </Button>

                                <Button
                                  onClick={() => {
                                    close();
                                    this.blacklistTrucker(
                                      this.state.selectedTransporterData
                                        .transporterId
                                    );
                                  }}
                                  style={deleteModalButton}
                                >
                                  Yes
                                </Button>
                              </div>
                            </div>
                          )}
                        </Popup>
                      ) : null}
                    </div>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Transporter Name *"
                          id="username"
                          formControlProps={{ fullWidth: true }}
                          success={this.state.nameState === 'success'}
                          error={this.state.nameState === 'error'}
                          inputProps={{
                            onChange: event =>
                              this.change(event, 'name', 'length', 1),
                            type: 'length',
                            value: this.state.name,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Contact Person "
                          id="contact-person"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: event =>
                              this.change(event, 'contactPerson', 'length', 0),
                            type: 'length',
                            value: this.state.contactPerson,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {!this.state.edit ? (
                            <CustomInput
                              labelText="Phone Number *"
                              id="phone-number"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                readOnly: true,
                                onChange: event =>
                                  this.change(event, 'phoneNumber', 'phone'),
                                type: 'phone',
                                value:
                                  activeRole == 'ops' || activeRole == 'admin'
                                    ? this.state.phoneNumber
                                    : `******${String(
                                        this.state.phoneNumber
                                      ).slice(-4)}`,
                              }}
                              success={
                                this.state.phoneNumberState === 'success'
                              }
                              error={this.state.phoneNumberState === 'error'}
                            />
                          ) : (
                            <div style={{ marginTop: '20px', width: '100%' }}>
                              <TextField
                                onChange={event => {
                                  const regex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
                                  if (regex.test(event.target.value)) {
                                    this.checkIfMultipleOrgs(
                                      event.target.value
                                    );
                                    this.setState({
                                      phoneNumber: event.target.value,
                                      phoneNumberState: 'success',
                                    });
                                  } else
                                    this.setState({
                                      phoneNumberState: 'error',
                                    });
                                }}
                                label="Phone Number *"
                                fullWidth
                                error={this.state.phoneNumberState === 'error'}
                              />
                            </div>
                          )}
                        </div>
                        <div>
                          {this.state.presentOrgs?.length > 0 ? (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',

                                flexWrap: 'wrap',
                              }}
                            >
                              <Typography
                                variant="caption"
                                gutterBottom
                                color="primary"
                              >
                                Org already exists with Phone Number &nbsp;
                              </Typography>
                              <Typography
                                variant="caption"
                                gutterBottom
                                color="primary"
                              >
                                (
                              </Typography>
                              {this.state.presentOrgs?.map((org, index) => (
                                <Typography
                                  variant="caption"
                                  gutterBottom
                                  color="primary"
                                  style={{
                                    marginRight: '5px',
                                    textDecoration: org?.blacklist
                                      ? 'line-through'
                                      : 'none',
                                  }}
                                >
                                  {org.company_name},
                                </Typography>
                              ))}
                              <Typography
                                variant="caption"
                                gutterBottom
                                color="primary"
                              >
                                )
                              </Typography>
                            </div>
                          ) : null}
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          success={this.state.emailState === 'success'}
                          error={this.state.emailState === 'error'}
                          labelText="Email address "
                          id="email-address"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: event =>
                              this.change(event, 'email', 'email'),
                            type: 'email',
                            value: this.state.email,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="GST Number "
                          id="gst-number"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: event =>
                              this.change(event, 'gstNumber', 'gst'),
                            type: 'gst',
                            value: this.state.gstNumber,
                          }}
                          success={this.state.gstNumberState === 'success'}
                          error={this.state.gstNumberState === 'error'}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <CustomInput
                            labelText="PAN Number"
                            id="pan-number"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              onChange: event =>
                                this.change(event, 'panNumber', 'pan'),
                              type: 'pan',
                              value: this.state.panNumber,
                            }}
                            success={this.state.panNumberState === 'success'}
                            error={this.state.panNumberState === 'error'}
                          />
                          {this.state.validatingPan ? (
                            <CircularProgress />
                          ) : null}
                        </div>

                        {this.state.panNumberState === 'error' ? (
                          <p style={{ color: 'red' }}>
                            {this.state.validationMessage}
                          </p>
                        ) : (
                          ''
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="TAN Number "
                          id="tan-number"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: event =>
                              this.change(event, 'tanNumber', 'tan'),
                            type: 'tan',
                            value: this.state.tanNumber,
                          }}
                          success={this.state.tanNumberState === 'success'}
                          error={this.state.tanNumberState === 'error'}
                        />
                      </GridItem>
                      {(this.props.activeRole === 'ops' ||
                        this.props.activeRole === 'sales' ||
                        this.props.activeRole === 'traffic' ||
                        this.props.activeRole === 'branch-ops') && (
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            style={{ display: 'flex', alignItems: 'baseline' }}
                          >
                            <FormControl component="fieldset">
                              <FormLabel component="legend">
                                TDS Deduction
                              </FormLabel>
                              <RadioGroup
                                aria-label="TDS Deduction"
                                name="TDS Deduction"
                                value={this.state.transporterTDSChecked}
                                onChange={this.handleCheck}
                                row
                              >
                                <FormControlLabel
                                  value="lobb"
                                  control={<Radio size="small" />}
                                  label={
                                    <Typography variant="caption">
                                      Deducted to Lobb
                                    </Typography>
                                  }
                                />
                                <FormControlLabel
                                  value="trucker"
                                  control={<Radio size="small" />}
                                  label={
                                    <Typography variant="caption">
                                      Deducted to Trucker
                                    </Typography>
                                  }
                                />
                                <FormControlLabel
                                  value="none"
                                  control={<Radio size="small" />}
                                  label={
                                    <Typography variant="caption">
                                      Deducted to None
                                    </Typography>
                                  }
                                />
                                {this.state.tdsDeductionChanged && (
                                  <MUIButton
                                    variant="contained"
                                    style={buttonStyles}
                                    size="small"
                                    onClick={this.handleSubmitTDS}
                                  >
                                    {this.state.isUpdatingTDS ? (
                                      <CircularProgress
                                        size={20}
                                        color="white"
                                      />
                                    ) : (
                                      'Submit'
                                    )}
                                  </MUIButton>
                                )}
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </GridItem>
                      )}
                      {(this.props.activeRole === 'ops' ||
                        this.props.activeRole === 'sales' ||
                        this.props.activeRole === 'traffic' ||
                        this.props.activeRole === 'branch-ops') && (
                        <GridItem xs={12} sm={12} md={6}>
                          <div
                            style={{ display: 'flex', alignItems: 'baseline' }}
                          >
                            <FormControl component="fieldset">
                              <FormLabel component="legend">
                                GST Billing
                              </FormLabel>
                              <RadioGroup
                                aria-label="GST Billing"
                                name="GST Billing"
                                value={this.state.transporterGSTChecked}
                                onChange={this.handleGstCheck}
                                row
                              >
                                <FormControlLabel
                                  value="true"
                                  control={<Radio size="small" />}
                                  label={
                                    <Typography variant="caption">
                                      Yes
                                    </Typography>
                                  }
                                />
                                <FormControlLabel
                                  value="false"
                                  control={<Radio size="small" />}
                                  label={
                                    <Typography variant="caption">
                                      No
                                    </Typography>
                                  }
                                />
                                {this.state.gstChanged && (
                                  <MUIButton
                                    variant="contained"
                                    style={buttonStyles}
                                    size="small"
                                    onClick={this.handleSubmitGST}
                                  >
                                    {this.state.isUpdatingGST ? (
                                      <CircularProgress
                                        size={20}
                                        color="white"
                                      />
                                    ) : (
                                      'Submit'
                                    )}
                                  </MUIButton>
                                )}
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </GridItem>
                      )}

                      <GridItem xs={12} sm={12} md={12}>
                        <TransporterNewFieldsView
                          activeRole={this.props.activeRole}
                          selectedTransporterData={
                            this.state.selectedTransporterData
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <SecondaryContactsView
                          classes={classes}
                          transporterData={this.state.selectedTransporterData}
                          activeRole={this.props.activeRole}
                          handleContacts={value => this.handleContacts(value)}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <LRUpload
                          transporterData={this.state.selectedTransporterData}
                          activeRole={this.props.activeRole}
                          transporterId={
                            this.state.selectedTransporterData.transporterId
                          }
                          featureFlagFiles={featureFlagFiles}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <GSTFileUpload
                          transporterData={this.state.selectedTransporterData}
                          activeRole={this.props.activeRole}
                          transporterId={
                            this.state.selectedTransporterData.transporterId
                          }
                          featureFlagFiles={featureFlagFiles}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <PANFileUpload
                          transporterData={this.state.selectedTransporterData}
                          activeRole={this.props.activeRole}
                          transporterId={
                            this.state.selectedTransporterData.transporterId
                          }
                          featureFlagFiles={featureFlagFiles}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <AdhaarFileUpload
                          transporterData={this.state.selectedTransporterData}
                          activeRole={this.props.activeRole}
                          transporterId={
                            this.state.selectedTransporterData.transporterId
                          }
                          featureFlagFiles={featureFlagFiles}
                        />
                      </GridItem>
                      {featureFlagFiles === 2 && (
                        <GridItem xs={12} sm={12} md={6}>
                          <AdhaarBackFileUploader
                            transporterData={this.state.selectedTransporterData}
                            activeRole={this.props.activeRole}
                            transporterId={
                              this.state.selectedTransporterData.transporterId
                            }
                          />
                        </GridItem>
                      )}
                    </GridContainer>
                    <GridContainer
                      direction="row"
                      alignItems="flex-start"
                      justifyContent="space-between"
                    >
                      <GridItem xs={12} sm={12} md={6}>
                        <GridContainer direction="column">
                          <GridItem xs={12} sm={12} md={12}>
                            <TransporterGTA
                              transporterData={
                                this.state.selectedTransporterData
                              }
                              activeRole={this.props.activeRole}
                              stylesClass={this.props}
                              transporterId={
                                this.state.selectedTransporterData.transporterId
                              }
                              featureFlagFiles={featureFlagFiles}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <OverAllRating
                              transporterData={
                                this.state.selectedTransporterData
                              }
                              activeRole={this.props.activeRole}
                              transporterId={
                                this.state.selectedTransporterData.transporterId
                              }
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CommentSection
                          transporterData={this.state.selectedTransporterData}
                        />
                      </GridItem>
                    </GridContainer>
                    {this.state.isLoading ? (
                      <CircularProgress
                        className={classes.progress}
                        style={{ color: purple[500] }}
                        thickness={7}
                      />
                    ) : (
                      <Button
                        color="warning"
                        onClick={this.submitTransporter}
                        className={classes.updateProfileButton}
                        disabled={
                          this.state.disableSubmit || featureFlagFiles === 1
                        }
                      >
                        Submit
                      </Button>
                    )}
                    <Clearfix />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          )
        )}
      </div>
    );
  }
}
AddTransporters.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
});

export default connect(mapStateToProps)(
  withStyles(userProfileStyles)(AddTransporters)
);
