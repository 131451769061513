import AdminDashboard from 'views/AdminDashboard/AdminDashboard.jsx';
import Transactions from 'views/Transactions/FinanceTransactions.jsx';
import OutstandingReportsView from 'views/Outstanding Reports/OutstandingReportsView.jsx';
// @material-ui/icons
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import Tune from '@material-ui/icons/Tune';
import Queued from '../views/Transactions/Sections/Queued';
import PODAgeingView from '../views/PODAgeing/PODAgeingView';
import TransporterOutstandingReports from '../views/Outstanding Reports/TransporterOutstanding/TransporterOutstandingReports';
import TruckerReports from '../views/Outstanding Reports/TruckerReports/TruckerReports';

const financeDashRoutes = [
  {
    path: '/finance/dashboard',
    name: 'Dashboard',
    icon: DashboardIcon,
    component: AdminDashboard,
  },
  {
    path: '/finance/transactions',
    name: 'Transactions',
    icon: SwapHoriz,
    component: Transactions,
  },
  {
    collapse: true,
    path: '/ops',
    name: 'Reports',
    icon: AccountBalanceWalletIcon,
    // state: 'openApprove',
    views: [
      {
        path: '/finance/podAgeing',
        name: 'POD Ageing',
        mini: 'P',
        component: PODAgeingView,
      },
      {
        path: '/finance/transporterOutstanding',
        name: 'Transporter Outstanding',
        mini: 'T O',
        component: TransporterOutstandingReports,
      },
    ],
  },
  {
    path: '/finance/queued',
    name: 'Queued',
    icon: Tune,
    component: Queued,
  },
  {
    redirect: true,
    path: '/finance',
    pathTo: '/finance/dashboard',
    name: 'Dashboard',
  },
];
export default financeDashRoutes;
