import React from 'react';
import PropTypes from 'prop-types';
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons

import DateRange from '@material-ui/icons/DateRange';
import LocalShipping from '@material-ui/icons/LocalShipping';
import PeopleOutline from '@material-ui/icons/PeopleOutline';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import firebase from '../../config/config';

import SalesTransaction from './Table/SalesTransaction';

const db = firebase.firestore();

class SalesDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transporters: '',
      companyId: '',
      uid: '',
      truckers: '',
      isLoading: false,
    };
    this.getTransportersData = this.getTransportersData.bind(this);
    this.getTruckersData = this.getTruckersData.bind(this);
  }

  componentDidMount() {
    // const userRef = db.collection("Users");
    this.getCounts();
  }

  getCounts() {
    const countsRef = db.collection('Counters');
    countsRef.doc('Transporters').onSnapshot(doc => {
      this.setState({ transporters: doc.data().count });
    });
    countsRef.doc('Truckers').onSnapshot(doc => {
      this.setState({ truckers: doc.data().count });
    });
  }

  /**
   * Will Get ALl the Transporters For The User
   * @function
   */
  getTransportersData(user) {
    const TransporterRef = db.collection(`Transporters`);
    TransporterRef.where('status', '==', 'approved')
      .get()
      .then(
        resultData => {
          const transporters = [];
          resultData.forEach(transporter => {
            //  Checks if transporter and user have any common branches
            if (
              user.branches.some(branch =>
                transporter.data().branches.includes(branch)
              )
            )
              transporters.push(transporter.data());
          });
          this.setState({ transporters });
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  /**
   * Will Get ALl the Truckers For The User
   * @function
   */
  getTruckersData(user) {
    const TruckerRef = db.collection('Truckers');
    TruckerRef.where('status', '==', 'approved')
      .get()
      .then(
        resultData => {
          const truckers = [];
          resultData.forEach(trucker => {
            //  Checks if transporter and user have any common branches
            if (
              user.branches.some(branch =>
                trucker.data().branches.includes(branch)
              )
            )
              truckers.push(trucker.data());
          });
          this.setState({
            truckers,
          });
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardHeader color="rose" stats icon>
                <CardIcon color="warning">
                  <PeopleOutline />
                </CardIcon>
                <p className={classes.cardCategory}>Transporters</p>
                <h3 className={classes.cardTitle}>
                  {this.state.transporters} <small />
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Last 24 Hours
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <LocalShipping />
                </CardIcon>
                <p className={classes.cardCategory}>Truckers</p>
                <h3 className={classes.cardTitle}> {this.state.truckers}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Last 24 Hours
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={10}>
            <SalesTransaction />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

SalesDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(SalesDashboard);
