/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// Core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CustomTabs from 'components/CustomTabs/CustomTabs.jsx';
import Table from 'components/Table/Table.jsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import history from '../../../history';
import {
  switchActivePage,
  setTransactions,
  setApprovedTransactions,
  setModifyTransactions,
  setPendingTransactions,
  setRejectedTransactions,
} from '../../../reducers/transactions';
import firebase from '../../../config/config';

const db = firebase.firestore();
const moment = require('moment');

class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      todaysPendingTransactions: [],
      tomorrowsPendingTransactions: [],
      pastPendingTransactions: [],
    };
    this.addTransactions = this.addTransactions.bind(this);
    this.switchTab = this.switchTab.bind(this);
    this.getTabs = this.getTabs.bind(this);
    this.unsubscribe = null;
  }

  getTable(transaction) {
    transaction = transaction.map(t => t.data);
    const { classes } = this.props;
    return (
      <div>
        {transaction.length > 0 ? (
          <Table
            tableHead={['#', 'Transporter', 'Freight', 'Receivable', 'Date']}
            tableData={transaction}
            customCellClasses={[classes.center]}
            customClassesForCells={[6, 5, 5]}
            customHeadCellClasses={[classes.center]}
            customHeadClassesForCells={[6, 5, 5]}
          />
        ) : (
          <div className={classes.textCenter}>
            <p className={`${classes.rajdhaniFont}`}>
              No Transactions Available
            </p>
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.getTransactionsData();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  getTabs() {
    return (
      <CustomTabs
        headerColor="#fff"
        tabs={[
          {
            tabName: `Today  ${this.state.todaysPendingTransactions.length}`,
            tabContent: this.switchTab('today'),
          },
          {
            tabName: `Past  ${this.state.pastPendingTransactions.length}`,
            tabContent: this.switchTab('past'),
          },
          {
            tabName: `Tomorrow ${this.state.tomorrowsPendingTransactions.length}`,
            tabContent: this.switchTab('tomorrow'),
          },
        ]}
      />
    );
  }

  switchTab(status) {
    if (status === 'today') {
      const { todaysPendingTransactions } = this.state;
      return this.getTable(todaysPendingTransactions);
    }
    if (status === 'tomorrow') {
      const { tomorrowsPendingTransactions } = this.state;
      return this.getTable(tomorrowsPendingTransactions);
    }
    if (status === 'past') {
      const { pastPendingTransactions } = this.state;
      return this.getTable(pastPendingTransactions);
    }
  }

  /**
   * will redirect to Add Transactions Page
   * @function
   */
  addTransactions() {
    history.push('/sales/transactions/add');
  }

  /**
   * Will Get ALl the Transactions For The User
   * @function
   */
  getTransactionsData() {
    this.setState({ isLoading: false });
    firebase.auth().onAuthStateChanged(user => {
      let transactionsRef;

      if (this.props.userDetails.isAdmin)
        transactionsRef = db
          .collection(`Transactions`)
          .where('status', '==', 'approved')
          .where('isEditedTransaction', '==', false);
      else
        transactionsRef = db
          .collection(`Transactions`)
          .where('agentId', '==', user.uid)
          .where('status', '==', 'approved')
          .where('isEditedTransaction', '==', false);

      this.unsubscribe = transactionsRef.onSnapshot(transactionsData => {
        const transactions = [];
        const todaysPendingTransactions = [];
        const tomorrowsPendingTransactions = [];
        const pastPendingTransactions = [];
        // this.setState({ isLoading: false });
        transactionsData.forEach(transaction => {
          if (transaction.data().athReceivableDate) {
            /*  var q = new Date();
          var m = q.getMonth() + 1;
          var d = q.getDate();
          var y = q.getFullYear();
          let today = d + "-" + m + "-" + y;
          */
            const expectedDate = moment(
              transaction.data().athReceivableDate,
              'MMM Do YY'
            ).startOf('day');
            const today = moment().startOf('day');
            const tomorrow = moment()
              .startOf('day')
              .add(1, 'days');
            const receivableAmount = Number(
              transaction.data().currentReceivableAmount
            );
            if (expectedDate.isBefore(today) && receivableAmount > 0) {
              pastPendingTransactions.push({
                color: 'danger',
                data: [
                  transaction.data().transactionSerial,
                  transaction.data().transporterName,
                  Intl.NumberFormat('en-IN').format(transaction.data().amount),
                  Intl.NumberFormat('en-IN').format(
                    transaction.data().currentReceivableAmount
                  ),
                  expectedDate.format('MMM Do YY'),
                ],
              });
            } else if (expectedDate.isSame(today) && receivableAmount > 0) {
              todaysPendingTransactions.push({
                color: 'warning',
                data: [
                  transaction.data().transactionSerial,
                  transaction.data().transporterName,
                  Intl.NumberFormat('en-IN').format(transaction.data().amount),
                  Intl.NumberFormat('en-IN').format(
                    transaction.data().currentReceivableAmount
                  ),
                  expectedDate.format('MMM Do YY'),
                ],
              });
            } else if (expectedDate.isSame(tomorrow) && receivableAmount > 0) {
              tomorrowsPendingTransactions.push({
                color: 'success',
                data: [
                  transaction.data().transactionSerial,
                  transaction.data().transporterName,
                  Intl.NumberFormat('en-IN').format(transaction.data().amount),
                  Intl.NumberFormat('en-IN').format(
                    transaction.data().currentReceivableAmount
                  ),
                  expectedDate.format('MMM Do YY'),
                ],
              });
            }
            const transactionResult = transaction.data();
            transactions.push(transactionResult);
          }
        });
        this.props.setTransactions(transactions);

        this.setState({
          // transactions,
          todaysPendingTransactions,
          pastPendingTransactions,
          tomorrowsPendingTransactions,
        });
      });
    });
  }

  render() {
    const { classes } = this.props;
    return <div />;
  }
}

Transactions.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  isModifyPage: state.transactions.isModifyPage,
  transactions: state.transactions.transactions,
  approvedTransactions: state.transactions.approvedTransactions,
  pendingTransactions: state.transactions.pendingTransactions,
  modifyTransactions: state.transactions.modifyTransactions,
  rejectedTransactions: state.transactions.rejectedTransactions,
  activeRole: state.main.activeRole,
  uid: state.main.uid,
  userDetails: state.main.userDetails,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setTransactions,
      setApprovedTransactions,
      setModifyTransactions,
      setPendingTransactions,
      setRejectedTransactions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(Transactions));
