/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import EditIcon from '@material-ui/icons/Edit';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';
import { RenderIf } from './RenderIf';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: 10,
  },
  body: {
    fontSize: 14,
    border: 'none',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    background: 'transparent',
    border: 'none',
  },
}))(TableRow);

function createData(index, customer, name, number, role, action) {
  return { index, customer, name, number, role, action };
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    tableLayout: 'fixed',
  },
  deleteButton: {
    background: 'red',
    color: 'white',
  },
});

export default function ListViewContacts({
  customers,
  handleContactPop,
  handleEdit,
  activeRole,
  truckerId,
}) {
  const classes = useStyles();
  const [selected, setSelected] = useState();
  // const [user, setUser] = useState();
  const [open, setOpen] = useState(false);

  const handleDelete = selected => {
    setOpen(false);
    handleEdit(selected);
  };
  const handleClear = item => {
    setOpen(true);
    setSelected(item);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // ?.filter(item => (selected ? item.number !== selected.number : item))
  const rows = customers?.map((item, index) =>
    createData(
      index,
      item,
      item.name,
      item.phoneNumber,
      item.role,
      <IconButton onClick={() => handleClear(item)}>
        <EditIcon fontSize="small" color="red" />
      </IconButton>
    )
  );

  // useEffect(() => {
  //   setContactPerson(contacts);
  //   const userDetails = firebase.auth().currentUser;
  //   setUser(userDetails);
  // }, [contactPerson]);

  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>

          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>

            <Button
              onClick={() => handleDelete(selected)}
              // color="error"
              variant="contained"
              autoFocus
              className={classes.deleteButton}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <TableContainer>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ width: '50px' }} />
              <StyledTableCell align="left">Full Name</StyledTableCell>

              <StyledTableCell align="right">Mobile Number</StyledTableCell>
              <StyledTableCell align="left">Role</StyledTableCell>

              <RenderIf isTrue={activeRole == 'ops'} style={{ width: '50px' }}>
                <StyledTableCell align="right" />
              </RenderIf>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map(row => (
              <StyledTableRow key={row.index}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  style={{ width: '50px' }}
                >
                  {row.index + 1}
                </StyledTableCell>
                <StyledTableCell align="left">{row.name}</StyledTableCell>

                <StyledTableCell align="right">{row.number}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.role === 'org'
                    ? 'Organisation'
                    : row.role === 'trip'
                    ? 'Trip'
                    : ''}
                </StyledTableCell>
                <RenderIf isTrue={activeRole == 'ops'}>
                  <StyledTableCell align="right" style={{ width: '50px' }}>
                    {row.action}
                  </StyledTableCell>
                </RenderIf>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
