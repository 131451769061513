/* eslint-disable prefer-const */
/* eslint-disable react/no-unused-state */
/* eslint-disable guard-for-in */
/* eslint-disable no-shadow */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-useless-escape */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["verifyRequired","verifyGst","verifyEmail","verifyNumber","verifyPan","verifyPhone","verifyLength"] }] */

import React from 'react';
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
// @material-ui/icons
import LocalShipping from '@material-ui/icons/LocalShipping';
import Add from '@material-ui/icons/Add';
import ErrorIcon from '@material-ui/icons/Error';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Table from 'components/Table/Table.jsx';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Edit from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';

import Danger from 'components/Typography/Danger.jsx';

import SweetAlert from 'react-bootstrap-sweetalert';
// import '../Truckers/truckerStyle.css';

// Import React Scrit Libraray to load Google object
// Fireabse
import AddTruckersStyle from 'assets/jss/material-dashboard-pro-react/views/AddTruckersStyle.jsx';
// import { validate } from '@material-ui/pickers';
import axios from 'axios';
import { Typography } from '@material-ui/core';
import MultiStepCapture from './FileUploads/MultiStepCapture';
import firebase from '../../config/config';
import apiCaller from '../../utils/apiCallerOutstanding';
import moment from 'moment';
import FeatureFlagToastAlert from '../Components/FeatureFlagToastAlert';
import swal from 'sweetalert';
// import SecondaryContacts from './OtherContacts/SecondaryContacts';

const { REACT_APP_OFFICE_REPORTS_API, REACT_APP_API_BASE_URL } = process.env;
const db = firebase.firestore();
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');
const addTrucker = firebase.functions().httpsCallable('truckers-addTrucker');
const setSecondaryContact = async (id, contacts) => {
  const podRef = await db.collection('Truckers').doc(id);
  // .collection('POD')
  // .doc(transporterId);
  for (const i in contacts) {
    try {
      podRef
        .collection('SecondaryContacts')
        .doc()
        .set(
          {
            name: contacts[i].name,
            number: contacts[i].number,
          },
          { merge: true }
        );
    } catch (error) {
      console.log('Error', error);
    }
  }
};
const validateBank = firebase
  .functions()
  .httpsCallable('truckers-validateBankNew');

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" {...props} ref={ref} />
));

class AddTruckers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameState: '',
      email: '',
      emailState: '',
      contactPerson: '',
      contactPersonState: '',
      phoneNumber: '',
      phoneNumberState: '',
      gstNumber: '',
      gstNumberState: '',
      panNumber: '',
      panNumberState: '',
      isLoading: false,
      alert: null,
      accountNumber: '',
      accountNumberState: '',
      ifsc: '',
      ifscState: '',
      bankHolderName: '',
      bankHolderNameState: '',
      branchName: '',
      branchNameState: '',
      bankName: '',
      bankNameState: '',
      truckType: '',
      truckTypeState: '',
      truckTypes: [],
      trucks: [],
      truckersData: [],
      trucksState: 'Please Add Truck Details',
      bankModal: false,
      truckNumber: '',
      banks: [],
      banksData: [],
      activeError: '',
      banksState: 'Please Add Bank Details',
      isLoadingBank: false,
      classicModal: false,
      secondaryContact: [],
      created: false,
      truckerId: '',
      validating: false,
      panValidation: false,
      validationMessage: '',
      duplicateTrucker: false,
      validatingPhone: false,
      validationFailed: false,
      presentOrgs: [],
      verifiedNameAtBank: '',
      isVerifyingbank: false,
      checkedVerifiednameAtBank: false,
      bankAccountDetailsVerified: false,
      featureFlagFiles: '',
      // secondaryContactPhoneNumber: '',
      // trigger: false,
    };
    this.getUserCompanyName = this.getUserCompanyName.bind(this);
    this.getAllTruckTypes = this.getAllTruckTypes.bind(this);
    this.change = this.change.bind(this);
    this.submitTrucker = this.submitTrucker.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.validateBankFields = this.validateBankFields.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.openEditBankForm = this.openEditBankForm.bind(this);
  }

  componentDidMount() {
    this.getUserCompanyName();
    this.getAllTruckTypes();
    this.getFeatureFlagStatus();
  }

  /**
   * Get the Users Company Name from the Database
   * @function
   */
  getUserCompanyName() {
    const self = this;
    firebase.auth().onAuthStateChanged(user => {
      const userRef = db.collection(`Users`).doc(`${user.uid}`);
      userRef
        .get()
        .then(
          resultData => {
            if (resultData.exists) {
              self.setState({
                myBranches: resultData.data().branches,
              });
            }
          },
          err => {
            console.log('err', err);
          }
        )
        .catch(err => {
          console.log('err', err);
        });
    });
  }

  getFeatureFlagStatus() {
    db.collection('FeatureFlags')
      .doc('FileUploadsFeature')
      .onSnapshot(doc => {
        if (doc.exists) {
          const data = doc.data();
          this.setState({ featureFlagFiles: data?.truckerFiles });
        }
      });
  }

  /**
   * will get all truck types from DB
   * @function
   */
  getAllTruckTypes() {
    const truckTypesRef = db.collection(`Global`).doc(`TruckTypes`);
    truckTypesRef
      .get()
      .then(
        resultData => {
          const { truckTypes } = resultData.data();
          this.setState({ truckTypes });
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  // Will Get select Trucks form
  getTruckTypes() {
    const { classes } = this.props;
    const { truckType, truckTypes, truckTypeState } = this.state;
    return (
      <div>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
            Select Truck Type
          </InputLabel>
          <Select
            MenuProps={{ className: classes.selectMenu }}
            classes={{ select: classes.select }}
            value={truckType}
            onChange={this.handleSimple}
            inputProps={{ name: 'truckType', id: 'simple-select' }}
          >
            <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
              Select Truck Type
            </MenuItem>
            {truckTypes.map((truckTypeData, i) => (
              <MenuItem
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={truckTypeData}
              >
                {truckTypeData}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {truckTypeState === 'error' && (
          <Danger>Please select truck type</Danger>
        )}
      </div>
    );
  }

  editBank() {
    const { classes } = this.props;
    const {
      bankHolderName,
      accountNumber,
      bankName,
      ifsc,
      branchName,
      bankId,
      banks,
      banksData,
    } = this.state;

    this.validateBankFields().then(
      success => {
        let index;
        banks.forEach((bank, i) => {
          if (bank.bankId === bankId) {
            index = i;
            if (
              banks[i].accountNumber !== accountNumber ||
              banks[i].bankName !== bankName ||
              banks[i].ifsc !== ifsc ||
              banks[i].bankBranchName !== branchName ||
              banks[i].bankHolderName !== bankHolderName
            ) {
              index = i;
              banks[i].accountNumber = accountNumber;
              banks[i].bankName = bankName;
              banks[i].ifsc = ifsc;
              banks[i].bankBranchName = branchName;
              banks[i].bankHolderName = bankHolderName;
            } else {
              // Edit not happened because data not changed or edited
            }
          }
        });
        banksData[index].data = [
          bankHolderName,
          accountNumber,
          bankName,
          ifsc,
          branchName,
          <GridItem xs={12} sm={12} md={6}>
            <div className={classes.block}>
              <FormControlLabel
                control={
                  <Switch
                    checked={banks[index].bankAccountActive}
                    onChange={this.handleChange(banks[index])}
                    value={banks[index].bankAccountActive}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      icon: classes.switchIcon,
                      iconChecked: classes.switchIconChecked,
                      bar: classes.switchBar,
                    }}
                  />
                }
              />
            </div>
          </GridItem>,
          <Button
            color="warning"
            className={classes.actionButton}
            key={index}
            onClick={() =>
              this.openEditBankForm({
                bankHolderName,
                accountNumber,
                bankName,
                bankId,
                ifsc,
                bankBranchName: branchName,
              })
            }
            justIcon
          >
            <Edit className={classes.icon} />
          </Button>,
        ];

        this.handleClose('bankModal');
        this.setState({
          banksData,
          banks,
          accountNumber: '',
          ifsc: '',
          bankName: '',
          branchName: '',
          bankHolderName: '',
          accountNumberState: '',
          ifscState: '',
          bankNameState: '',
          branchNameState: '',
          bankHolderNameState: '',
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  // eslint-disable-next-line react/sort-comp
  openEditBankForm(bank) {
    const {
      bankHolderName,
      accountNumber,
      bankName,
      ifsc,
      bankBranchName,
      bankId,
    } = bank;
    this.setState({
      bankHolderName,
      accountNumber,
      bankName,
      ifsc,
      branchName: bankBranchName,
      bankId,
    });
    this.handleClickOpen('bankModal');
  }

  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  // Modal Window To Add The Trucks
  getSweetAlertForm() {
    const { classes } = this.props;
    const { classicModal, truckNumber, truckNumberState } = this.state;
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        classes={{ root: classes.center, paper: classes.modal }}
        style={{ maxWidth: '400px !important', textAlign: 'center' }}
        open={classicModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('classicModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => this.handleClose('classicModal')}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>Add Truck Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Truck Number *"
                id="truck-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'truckNumber', 'length', 0),
                  type: 'length',
                  value: truckNumber.replace(/\s/g, ''),
                }}
                success={truckNumberState === 'success'}
                error={truckNumberState === 'error'}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              {this.getTruckTypes()}
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button color="primary" onClick={() => this.addTrucks()}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable() {
    const { classes } = this.props;
    const { truckersData, trucksState } = this.state;
    return (
      <Card>
        <CardHeader style={{ marginBottom: '2px', paddingBottom: '2px' }}>
          <h4>Add Trucks</h4>
        </CardHeader>
        {truckersData.length > 0 ? (
          <CardBody className={classes.customCardContentClass}>
            <Table
              hover
              tableHead={['Truck Number', 'Type']}
              tableData={truckersData}
            />
          </CardBody>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <p
              className={`${classes.textCenter} ${classes.title}`}
              style={{ textAlign: 'center', color: 'red' }}
            >
              {trucksState}
            </p>
          </div>
        )}
        <CardFooter>
          <Button
            color="info"
            onClick={() => this.handleClickOpen('classicModal')}
            className={classes.updateProfileButton}
            justIcon
          >
            <Add />
          </Button>
        </CardFooter>
      </Card>
    );
  }

  // Modal Window To Add The Bank Details
  getBankDetailsForm() {
    const { classes } = this.props;
    const {
      bankModal,
      bankHolderName,
      bankHolderNameState,
      accountNumber,
      accountNumberState,
      ifsc,
      ifscState,
      bankName,
      bankNameState,
      branchName,
      branchNameState,
    } = this.state;
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        classes={{ root: classes.center, paper: classes.modal }}
        style={{ maxWidth: '400px !important', textAlign: 'center' }}
        open={bankModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('bankModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => this.handleClose('bankModal')}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>Add Bank Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Bank Holder Name"
                id="bank Holder Name"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'bankHolderName', 'alpha'),
                  value: bankHolderName,
                }}
                success={bankHolderNameState === 'success'}
                error={bankHolderNameState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Account Number"
                id="account-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'accountNumber', 'alphanum'),
                  // type: 'length',
                  type: 'alphanum',
                  value: accountNumber,
                }}
                success={accountNumberState === 'success'}
                error={accountNumberState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="IFSC Code"
                id="ifsc-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event => this.change(event, 'ifsc', 'ifsc'),
                  type: 'ifsc',
                  value: ifsc,
                }}
                success={ifscState === 'success'}
                error={ifscState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Bank name"
                id="bank-name"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'bankName', 'length', 2),
                  type: 'length',
                  value: bankName,
                }}
                success={bankNameState === 'success'}
                error={bankNameState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Enter your bank branch name"
                id="branch-name"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'branchName', 'length', 1),
                  type: 'length',
                  value: branchName,
                }}
                success={branchNameState === 'success'}
                error={branchNameState === 'error'}
              />
            </GridItem>
            {this.state.verifiedNameAtBank && (
              <GridItem xs={12} sm={12} md={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                  }}
                >
                  <h4 style={{ fontWeight: 'bold' }}>Name At Bank</h4>
                  <p
                    style={{
                      color: '#2CAE76',
                      fontWeight: 'bold',
                      margin: 0,
                    }}
                  >
                    {this.state.verifiedNameAtBank}
                  </p>
                </div>
              </GridItem>
            )}
          </GridContainer>
        </DialogContent>
        <p style={{ color: 'red' }}>{this.state.validateError}</p>
        <DialogActions className={classes.modalFooter}>
          {this.state.isVerifyingbank ? (
            <CircularProgress
              // className={classes.progress}
              style={{ color: purple[500] }}
              thickness={4}
              // variant="indeterminate"
              disableShrink
            />
          ) : (
            <Button color="primary" onClick={() => this.verifyBanks()}>
              Verify
            </Button>
          )}
          {this.state.isLoadingBank ? (
            <CircularProgress
              className={classes.progress}
              style={{ color: purple[500] }}
              thickness={4}
            />
          ) : (
            <Button
              disabled={!this.state.bankAccountDetailsVerified}
              color="primary"
              onClick={() => this.addBanks()}
            >
              Add
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  handleContacts(value) {
    this.setState({
      secondaryContact: value,
      // secondaryContactPhoneNumber: value.number,
    });
  }

  /**
   * Will return the Bank Details Component
   * @function
   */
  getBanks() {
    const { classes } = this.props;
    const { banksData, banksState, activeError } = this.state;
    return (
      <Card>
        <CardHeader style={{ marginBottom: '2px', paddingBottom: '2px' }}>
          <h4>Add Bank Accounts</h4>
        </CardHeader>
        {banksData.length > 0 ? (
          <React.Fragment>
            <CardBody className={classes.customCardContentClass}>
              <Table
                hover
                tableHead={['Name', 'Account No', 'Bank', 'Ifsc', 'Branch']}
                tableData={banksData}
              />
            </CardBody>

            <div style={{ textAlign: 'center' }}>
              <p
                className={`${classes.textCenter} ${classes.title}`}
                style={{ textAlign: 'center', color: 'red' }}
              >
                {activeError}
              </p>
            </div>
          </React.Fragment>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <p
              className={`${classes.textCenter} ${classes.title}`}
              style={{ textAlign: 'center', color: 'red' }}
            >
              {banksState}
            </p>
          </div>
        )}
        <CardFooter>
          <Button
            color="info"
            onClick={() => this.handleClickOpen('bankModal')}
            className={classes.updateProfileButton}
            justIcon
          >
            <Add />
          </Button>
        </CardFooter>
      </Card>
    );
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // validates all required fields and returns a promise
  validateAllFields() {
    const {
      name,
      banks,
      phoneNumber,
      trucks,
      contactPerson,
      panNumber,
      panNumberState,
    } = this.state;
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Name
      // here  previously used verifyLength method
      if (this.verifyRequired(name)) {
        this.setState({
          nameState: 'success',
        });
      } else {
        this.setState({
          nameState: 'error',
        });
        error = true;
      }
      if (this.verifyRequired(contactPerson)) {
        this.setState({
          contactPersonState: 'success',
        });
      } else {
        this.setState({
          contactPersonState: 'error',
        });
        error = true;
      }
      if (panNumberState !== 'error' && panNumber.length > 0) {
        this.setState({ validationMessage: '' });
      } else {
        this.setState({
          panNumberState: 'error',
          validationMessage: 'Please check PAN Number',
        });
        error = true;
      }

      if (trucks.length > 0) {
        this.setState({ trucksState: '' });
      } else {
        this.setState({ trucksState: 'Please Add Truck Details' });
        error = true;
      }

      if (banks.length > 0) {
        this.setState({ banksState: '' });
      } else {
        this.setState({ banksState: 'Please Add Bank Details' });
        error = true;
      }

      // Verify Phone Number
      if (this.verifyPhone(phoneNumber)) {
        this.setState({
          phoneNumberState: 'success',
        });
      } else {
        this.setState({
          phoneNumberState: 'error',
        });
        error = true;
      }

      let numActiveBanks = 0;
      banks.forEach(bank => {
        if (bank.bankAccountActive) {
          numActiveBanks += 1;
        }
      });
      if (numActiveBanks !== 1) {
        // console.log('Hi');
        this.setState({
          activeError: 'Exactly 1 bank must be active.',
        });
        error = true;
      } else {
        // console.log('Hddi');
        this.setState({
          activeError: '',
        });
      }

      if (!error) {
        resolve(true);
      } else {
        reject(error);
      }
    });
  }

  /**
   * Submits the Trucker details to the Database
   * @function
   */
  submitTrucker() {
    const self = this;
    const {
      name,
      email,
      contactPerson,
      phoneNumber,
      gstNumber,
      panNumber,
      trucks,
      banks,
    } = this.state;
    const trimmedName = name.trim();
    const { userDetails, systemDetails } = this.props;
    this.setState({
      isLoading: true,
    });
    this.validateAllFields()
      .then(
        success => {
          if (success) {
            const user = firebase.auth().currentUser;
            const body = {
              name: trimmedName,
              email,
              contactPerson,
              phoneNumber,
              gstNumber,
              panNumber,
              trucks,
              updatedBy: user.uid,
              status: 'pending',
              createdBy: userDetails.uid,
              editedBy: '',
              createdByName: userDetails.name,
              editedByName: '',
              firstTransaction: false,
              isActive: false,
              banks,
            };
            addTrucker(body)
              .then(userDoc => {
                const ID = userDoc.data.userId;
                setSecondaryContact(ID, this.state.secondaryContact);
                this.setState({ truckerId: ID });
                const auditBody = {
                  data: {
                    name,
                    email,
                    contactPerson,
                    phoneNumber,
                    gstNumber,
                    panNumber,
                    trucks,
                    banks,
                    status: 'pending',
                  },
                  collection: 'Truckers',
                  updatedBy: userDetails.uid,
                  systemDetails,
                  type: 'Create Trucker',
                  message: `${userDetails.email} created Trucker with name ${name}`,
                };

                //  Banks
                const auditBody2 = {
                  data: {
                    banks,
                  },
                  collection: 'Truckers',
                  updatedBy: userDetails.uid,
                  systemDetails,
                  type: 'Bank',
                  message: `${
                    userDetails.email
                  } added a Trucker ${name} with banks ${banks
                    .map(bank => JSON.stringify(bank))
                    .join()}`,
                };
                auditLog(auditBody);
                auditLog(auditBody2);
                // Updated Succeessfully
                // this.clearForm();
                this.setState({ isLoading: false });
                this.setState({ created: true });
              })
              .catch(err => {
                console.log('error: ', err?.message);
                if (err?.message === 'Trucker blacklisted.') {
                  swal(
                    'Oops!',
                    'A user with the entered phone number already exists and is blacklisted.',
                    'error'
                  );
                }
                const errorBody = {
                  message: `${userDetails.email} tried to create Trucker with name ${name} but FAILED`,
                  status: 'fail',
                  error: err,
                  updatedBy: userDetails.uid,
                  errorMessage: err.message,
                  collection: 'Truckers',
                  systemDetails,
                };
                auditLog(errorBody);
                this.setState({ isLoading: false });
              });
          }
        },
        err => {
          console.log(err);
          self.setState({
            isLoading: false,
          });
        }
      )
      .catch(err => {
        console.log(err);
        self.setState({
          isLoading: false,
        });
      });
  }

  /**
   * Will Clears ALl the State values after form submision
   * @function
   */
  clearForm() {
    const { classes } = this.props;
    const self = this;
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Good job!"
          onConfirm={() => self.hideAlert()}
          onCancel={() => self.hideAlert()}
          confirmBtnCssClass={`${classes.button} ${classes.success}`}
        >
          Trucker Added Succeessfully
        </SweetAlert>
      ),
    });
    self.setState({
      name: '',
      nameState: '',
      email: '',
      emailState: '',
      contactPerson: '',
      contactPersonState: '',
      phoneNumber: '',
      phoneNumberState: '',
      creditLimit: '',
      creditLimitState: '',
      gstNumber: '',
      gstNumberState: '',
      panNumber: '',
      panNumberState: '',
      city: '',
      cityState: '',
      state: '',
      stateState: '',
      postalCode: '',
      postalCodeState: '',
      bankHolderName: '',
      banks: [],
      banksData: [],
      truckersData: [],
    });
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'required':
        if (this.verifyRequired(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'alpha':
        if (this.verifyAlpha(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'pan':
        if (this.verifyPan(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
            validationMessage: 'Please check PAN Number',
          });
        }
        break;
      case 'phone':
        if (this.verifyPhone(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'ifsc':
        if (this.verifyIfsc(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'gst':
        if (this.verifyGst(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'alphanum':
        if (this.verifyAlphanumeric(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  /**
   * closes the modal button
   * @param {*} modal
   */
  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.history.push('/sales/truckers/view');
  }

  // function that returns true if value is there, false otherwise
  verifyRequired(value) {
    const nameRegex = /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/;
    if (nameRegex.test(value) && value.length < 30) {
      return true;
    }
    return false;
  }

  // function that returns true if value is Valid GST, false otherwise
  verifyGst(value) {
    const gstRex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (gstRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyAlpha(value) {
    if (value) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  async validatePanNumber(pan) {
    // this.setState({ panValidation: true });

    const endPoint = 'pipe/pan-validate';
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const token = Aa;
    const body = {
      type: 'trucker',
      pan,
    };
    const response = await axios.post(
      `${REACT_APP_API_BASE_URL}${endPoint}`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const duplicate = response?.data?.Info.pan_match;

    if (duplicate) {
      this.setState({
        panValidation: true,
        panNumberState: 'error',
        validationMessage: 'PAN Number already exists',
      });
    } else {
      this.setState({ panNumberState: 'success' });
    }
  }

  // function that verifies if value contains PanCard
  verifyPan(value) {
    const regpan = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    if (regpan.test(value)) {
      this.validatePanNumber(value);
    }
    return false;
  }

  searchTrucker = async phone => {
    this.setState({ validatingPhone: true });
    let status = false;
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const token = Aa;
    try {
      const endPoint = 'search-office-user';
      const body = {
        type: 'truckers',
        pan: '',
        contact_person: '',
        phone_number: phone,
        name: '',
        limit: 10,
        offset: 0,
      };
      const response = await axios.post(
        `${REACT_APP_OFFICE_REPORTS_API}${endPoint}`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = response.data.body;
      if (data.length > 0) {
        this.setState({ duplicateTrucker: true, presentOrgs: data });
        status = false;
      } else {
        this.setState({ duplicateTrucker: false, presentOrgs: data });
        status = true;
      }
      this.setState({ validatingPhone: false });
    } catch (e) {
      this.setState({ duplicateTrucker: false, validationFailed: false });
      status = true;
      this.setState({ validatingPhone: false });
    }
    // return status;
  };

  // function that verifies if value contains PanCard
  verifyPhone(value) {
    const regPhone = /^[6-9][0-9]{9}$/;
    if (regPhone.test(value) && value.length === 10) {
      if (value.length === 10) {
        this.searchTrucker(value);
      }
      return true;
    }
    return false;
  }

  // a function that verifies if the input param is alphanumeric
  verifyAlphanumeric(value) {
    const alphaRex = /^[a-zA-Z0-9]+$/;
    if (alphaRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    // const regexName = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;

    const regexName = /^[a-z\d\-_\s]+$/i;
    // if (value.length >= length) {

    // }
    if (regexName.test(value) && value.length < 30) {
      return true;
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  verifyIfsc(value) {
    // const regIfsc = /^[A-Za-z]{4}0[A-Z0-9]{6}$/;
    const regIfsc = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    if (regIfsc.test(value)) {
      return true;
    }
    return false;
  }

  // Add Trucks
  addTrucks() {
    const { userDetails } = this.props;
    const { trucks, truckersData, truckType } = this.state;
    let { truckNumber } = this.state;
    if (truckNumber === '') {
      // do nothing
    } else {
      truckNumber = truckNumber.replace(/[\.@#$%^&*()+=!~`<>{}|,:]+/g, '');
      truckNumber = truckNumber.replace(/[ -,_,-]+/g, '');
    }
    if (this.verifyLength(truckType, 1)) {
      this.setState({ truckTypeState: '' });
    } else {
      this.setState({ truckTypeState: 'error' });
    }

    if (this.verifyLength(truckNumber, 1)) {
      this.setState({
        truckNumberState: 'success',
        truckTypeState: '',
        truckNumber,
      });
      trucks.push({
        truckNumber,
        truckType,
        updatedByUid: userDetails?.uid,
        updatedByName: userDetails?.name,
        updatedAt: moment().format('llll'),
      });
      truckersData.push({
        color: 'success',
        data: [truckNumber, truckType],
      });
      this.handleClose('classicModal');
      this.setState({
        truckersData,
        trucks,
        truckNumber: '',
        truckType: '',
      });
    } else {
      this.setState({
        truckNumberState: 'error',
      });
    }
  }

  // Will Verify Bank Details
  validateBankFields() {
    const { accountNumber, ifsc, branchName, bankName } = this.state;

    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Account Number
      if (this.verifyAlphanumeric(accountNumber)) {
        this.setState({
          accountNumberState: 'success',
        });
      } else {
        this.setState({ accountNumberState: 'error' });
        error = true;
      }

      // Verify Account Number
      // if (this.verifyAlpha(bankHolderName)) {
      //   this.setState({
      //     bankHolderNameState: 'success',
      // });

      if (this.verifyAlpha(accountNumber)) {
        this.setState({
          bankHolderNameState: 'success',
        });
      } else {
        this.setState({ bankHolderNameState: 'error' });
        error = true;
      }
      // Verify IFSC
      if (this.verifyIfsc(ifsc)) {
        this.setState({
          ifscState: 'success',
        });
      } else {
        this.setState({ ifscState: 'error' });
        error = true;
      }

      // Verify Bank Name
      if (this.verifyLength(bankName, 2)) {
        this.setState({ bankNameState: 'success' });
      } else {
        this.setState({ bankNameState: 'error' });
        error = true;
      }

      // Verify Bank Branch
      if (this.verifyLength(branchName, 2)) {
        this.setState({ branchNameState: 'success' });
      } else {
        this.setState({ branchNameState: 'error' });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        reject(error);
      }
    });
  }

  handleChange = currentBank => event => {
    const { classes } = this.props;
    const { accountNumber, bankHolderName } = currentBank;
    const { banks, banksData } = this.state;
    banks.forEach((bank, i) => {
      if (
        banks[i].accountNumber === accountNumber &&
        banks[i].bankHolderName === bankHolderName
      ) {
        if (banks[i].bankAccountActive !== event.target.checked) {
          // If Bank Edit is Success (Compared with the Previous values)
          banks[i].bankAccountActive = event.target.checked;
          banksData[i].data = [
            currentBank.bankHolderName,
            currentBank.accountNumber,
            currentBank.bankName,
            currentBank.ifsc,
            currentBank.bankBranchName,
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.block}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={banks[i].bankAccountActive}
                      onChange={this.handleChange(banks[i])}
                      value={banks[i].bankAccountActive}
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        icon: classes.switchIcon,
                        iconChecked: classes.switchIconChecked,
                        bar: classes.switchBar,
                      }}
                    />
                  }
                />
              </div>
            </GridItem>,
          ];
        }
      } else if (event.target.checked) {
        banks[i].bankAccountActive = !event.target.checked;
        banksData[i].data = [
          banks[i].bankHolderName,
          banks[i].accountNumber,
          banks[i].bankName,
          banks[i].ifsc,
          banks[i].bankBranchName,
          <GridItem xs={12} sm={12} md={6}>
            <div className={classes.block}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!event.target.checked}
                    onChange={this.handleChange(banks[i])}
                    value={!event.target.checked}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      icon: classes.switchIcon,
                      iconChecked: classes.switchIconChecked,
                      bar: classes.switchBar,
                    }}
                  />
                }
              />
            </div>
          </GridItem>,
        ];
      }
    });
    this.setState({
      banksData,
      banks,
    });
  };

  async checkbankDetails(data) {
    const currentUserDetails = firebase.auth().currentUser;
    const { Aa } = currentUserDetails;
    const userToken = Aa;
    const endPoint = 'pipe/bank';
    try {
      const result = await apiCaller(
        endPoint,
        data,
        `Bearer ${userToken}`,
        'pipe'
      );
      return result;
    } catch (err) {
      throw new Error(err);
    }
  }

  verifyBanks() {
    const { accountNumber, ifsc, bankHolderName, phoneNumber } = this.state;

    this.validateBankFields()
      .then(success => {
        this.setState({
          isVerifyingbank: true,
        });
        const body = {
          type: 'verify-bank',
          bank_account_name: bankHolderName,
          bank_account_number: accountNumber,
          ifsc_code: ifsc,
          phone_number: phoneNumber,
        };

        this.checkbankDetails(body)
          .then(result => {
            const verified = result?.data?.account_verified;
            const nameAtbank = result?.data?.name_at_bank;
            if (verified) {
              this.setState({
                verifiedNameAtBank: nameAtbank,
                isVerifyingbank: false,
                bankAccountDetailsVerified: true,
              });
            }
            //
          })
          .catch(err =>
            this.setState({
              isVerifyingbank: false,
            })
          );
      })
      .catch(err => {
        this.setState({
          isVerifyingbank: false,
        });
      });
  }

  // Will Add Banks
  addBanks() {
    const { classes } = this.props;
    const {
      banks,
      banksData,
      accountNumber,
      bankName,
      branchName,
      bankHolderName,
      ifsc,
      phoneNumber,
    } = this.state;
    this.validateBankFields()
      .then(success => {
        this.setState({
          isLoadingBank: true,
        });
        const body = {
          account_number: accountNumber,
          ifsc_code: ifsc,
          account_name: bankHolderName,
          phone: phoneNumber,
        };
        validateBank(body)
          .then(res => {
            if (res.data.data.hasOwnProperty('all_banks')) {
              const bankData = res.data.data.all_banks;
              bankData.forEach(newBank => {
                if (newBank.account_verified) {
                  this.setState({ isLoadingBank: false, validateError: '' });
                  const bank = {
                    accountNumber,
                    bankName,
                    ifsc,
                    bankBranchName: branchName,
                    bankHolderName,
                    bankAccountActive: true,
                  };
                  if (banks.length > 0) {
                    banks.forEach((bank, i) => {
                      banks[i].bankAccountActive = false;
                    });
                    banksData.forEach((bank, i) => {
                      banksData[i].data[5] = (
                        <GridItem xs={12} sm={12} md={6}>
                          <div className={classes.block}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={false}
                                  onChange={this.handleChange(bank)}
                                  value={false}
                                  classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    icon: classes.switchIcon,
                                    iconChecked: classes.switchIconChecked,
                                    bar: classes.switchBar,
                                  }}
                                />
                              }
                            />
                          </div>
                        </GridItem>
                      );
                    });
                  }
                  banks.push({
                    accountNumber,
                    bankName,
                    ifsc,
                    bankBranchName: branchName,
                    bankHolderName,
                    bankAccountActive: true,
                  });
                  banksData.push({
                    color: 'success',
                    data: [
                      bankHolderName,
                      accountNumber,
                      bankName,
                      ifsc,
                      branchName,
                      <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.block}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked
                                onChange={this.handleChange(bank)}
                                value
                                classes={{
                                  switchBase: classes.switchBase,
                                  checked: classes.switchChecked,
                                  icon: classes.switchIcon,
                                  iconChecked: classes.switchIconChecked,
                                  bar: classes.switchBar,
                                }}
                              />
                            }
                          />
                        </div>
                      </GridItem>,
                    ],
                  });
                  this.handleClose('bankModal');
                  this.setState({
                    banksData,
                    banks,
                    accountNumber: '',
                    ifsc: '',
                    bankName: '',
                    branchName: '',
                    bankHolderName: '',
                    accountNumberState: '',
                    ifscState: '',
                    bankNameState: '',
                    branchNameState: '',
                    bankHolderNameState: '',
                  });
                } else {
                  this.setState({
                    isLoadingBank: false,
                    validateError:
                      'We are unable to validate the bank details. Please check if the bank details are correct.',
                  });
                }
              });
            }
          })
          .catch(err => {
            this.setState({
              isLoadingBank: false,
              validateError: 'Something went wrong! Please try again.',
            });
          });
      })
      .catch(err => err);
  }

  render() {
    const { classes, userDetails, activeRole } = this.props;
    const {
      alert,
      nameState,
      name,
      contactPerson,
      contactPersonState,
      phoneNumber,
      phoneNumberState,
      email,
      emailState,
      gstNumber,
      gstNumberState,
      panNumber,
      panNumberState,
      isLoading,
      created,
      truckerId,
      featureFlagFiles,
    } = this.state;
    return (
      <div>
        {featureFlagFiles === 1 && <FeatureFlagToastAlert />}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {alert}
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="warning">
                <CardIcon color="primary" className={`${classes.cardHeader}`}>
                  <LocalShipping />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Add Trucker <small />
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Trucker Name *"
                      id="username"
                      formControlProps={{ fullWidth: true }}
                      success={nameState === 'success'}
                      error={nameState === 'error'}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'name', 'required', 1),
                        type: 'required',
                        value: name,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Contact Person *"
                      id="contact-person"
                      formControlProps={{ fullWidth: true }}
                      success={contactPersonState === 'success'}
                      error={contactPersonState === 'error'}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'contactPerson', 'length', 0),
                        type: 'length',
                        value: contactPerson,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: '100%' }}>
                        <CustomInput
                          labelText="Phone Number *"
                          id="phone-number"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: event =>
                              this.change(event, 'phoneNumber', 'phone'),
                            type: 'phone',
                            value: phoneNumber,
                          }}
                          success={phoneNumberState === 'success'}
                          error={phoneNumberState === 'error'}
                        />
                        {this.state.presentOrgs?.length > 0 ? (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',

                              flexWrap: 'wrap',
                            }}
                          >
                            <Typography
                              variant="caption"
                              gutterBottom
                              color="primary"
                            >
                              Org already exists with Phone Number. &nbsp;
                            </Typography>
                            <Typography
                              variant="caption"
                              gutterBottom
                              color="primary"
                            >
                              (
                            </Typography>
                            {this.state.presentOrgs?.map((org, index) => (
                              <Typography
                                variant="caption"
                                gutterBottom
                                color="primary"
                                style={{
                                  marginRight: '5px',
                                  textDecoration: org?.blacklist
                                    ? 'line-through'
                                    : 'none',
                                }}
                              >
                                {org.company_name},
                              </Typography>
                            ))}
                            <Typography
                              variant="caption"
                              gutterBottom
                              color="primary"
                            >
                              )
                            </Typography>
                          </div>
                        ) : null}
                      </div>
                      {this.state.validatingPhone ? <CircularProgress /> : null}
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      success={emailState === 'success'}
                      error={emailState === 'error'}
                      labelText="Email address "
                      id="email-address"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => this.change(event, 'email', 'email'),
                        type: 'email',
                        value: email,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="GST Number "
                      id="gst-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'gstNumber', 'gst'),
                        type: 'gst',
                        value: gstNumber,
                      }}
                      success={gstNumberState === 'success'}
                      error={gstNumberState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={
                        <div style={{ display: 'flex', alignItmes: 'center' }}>
                          <p>PAN Number *</p>
                          {this.state.panNumberState === 'error' && (
                            <Tooltip title={this.state.validationMessage}>
                              <InfoIcon
                                fontSize="small"
                                style={{ marginLeft: '10px', color: 'red' }}
                              />
                            </Tooltip>
                          )}
                        </div>
                      }
                      id="pan-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'panNumber', 'pan'),
                        type: 'pan',
                        value: panNumber,
                      }}
                      success={panNumberState === 'success'}
                      error={panNumberState === 'error'}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {this.getBanks()}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={5}>
                    {this.getTable()}
                  </GridItem>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      {this.getBankDetailsForm()}
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      {this.getSweetAlertForm()}
                    </GridItem>
                  </GridContainer>
                </GridContainer>
                {isLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={7}
                  />
                ) : (
                  <Button
                    color="warning"
                    onClick={this.submitTrucker}
                    className={classes.updateProfileButton}
                    disabled={featureFlagFiles === 1}
                  >
                    Next
                  </Button>
                )}
                <Clearfix />
                {created ? (
                  <MultiStepCapture
                    truckerid={truckerId}
                    created={created}
                    trucks={this.state.trucks}
                    userDetails={userDetails}
                    activeRole={activeRole}
                    clearform={() => this.clearForm()}
                    featureFlagFiles={this?.state?.featureFlagFiles}
                  />
                ) : (
                  ''
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
AddTruckers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
  activeRole: state.main.activeRole,
});

export default connect(mapStateToProps)(
  withStyles(AddTruckersStyle)(AddTruckers)
);
